import { Link } from 'lib/link';
import styled, { css } from 'styled-components';

const styledLinkInteractionStates = css`
  &:focus {
    text-decoration: none;
  }

  &:hover {
    color: currentColor;
    text-decoration: none;
  }
`;

const PageHeaderLink = styled(Link)`
  && {
    ${styledLinkInteractionStates}
    align-self: center;
    background-color: ${({ theme }) => theme.color.primary[700].value};
    border-radius: ${({ theme }) => theme.size.borderRadius.small.value};
    box-sizing: border-box;
    color: currentColor;
    padding: 0 ${({ theme }) => theme.size.spacing.medium.value} 0 0;
    position: relative;

    &:not(:visited) {
      ${styledLinkInteractionStates}
      color: currentColor;
    }

    &:active {
      background-color: ${({ theme }) => theme.color.primary[800].value};
    }

    &:visited {
      color: currentColor;

      &:hover,
      &:focus {
        color: currentColor;
      }
    }
  }
`;

export { PageHeaderLink };
