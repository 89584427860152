import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { STEPPER_VARIANTS } from '../constants';

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  ${({ variant }) =>
    variant === STEPPER_VARIANTS.VERTICAL
      ? css`
          flex-direction: row;
        `
      : css`
          align-items: center;
          justify-content: center;
          flex-direction: column;
        `}
`;

export const StepContainer = ({ children, ...other }) => {
  return <StyledContainer {...other}>{children}</StyledContainer>;
};

StepContainer.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node,
  /** Variant of the stepper */
  variant: PropTypes.oneOf(Object.values(STEPPER_VARIANTS)),
};

StepContainer.defaultProps = {
  children: undefined,
  variant: STEPPER_VARIANTS.HORIZONTAL,
};
