import React from 'react';
import styled, { css } from 'styled-components';

import { BADGE_POSITIONS } from '../constants';
import { Badge } from '../elements';
import { getRem } from './../../core';

const BADGE_POSITION_OFFSET = getRem('-2px');

const StyledBadgeWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  top: ${BADGE_POSITION_OFFSET};
  z-index: ${({ theme }) => theme.zIndex.badge.value};
  ${({ position }) => {
    if (position === BADGE_POSITIONS.LEFT) {
      return css`
        left: ${BADGE_POSITION_OFFSET};
      `;
    }
    return css`
      right: ${BADGE_POSITION_OFFSET};
    `;
  }}
`;

const withBadge = (WrappedComponent, { dataTestId, id, ...otherBadgeProps }) => {
  const WrappedComponentWithBadge = (props) => (
    <StyledBadgeWrapper data-testid={dataTestId ? `${dataTestId}-wrapper` : undefined}>
      <StyledBadge id={id} dataTestId={dataTestId} {...otherBadgeProps} />
      <WrappedComponent aria-describedby={id} {...props} />
    </StyledBadgeWrapper>
  );

  WrappedComponentWithBadge.propTypes = WrappedComponent.propTypes;

  return WrappedComponentWithBadge;
};

export { withBadge };
