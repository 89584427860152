import React from 'react';
import PropTypes from 'prop-types';

import { PanelChevron, PanelHeaderWrapper, PanelTitle } from '../elements';

const PanelHeader = React.forwardRef(
  ({ dataTestId, isOpen, panelContentId, renderAfterTitle, title, ...other }, ref) => (
    <PanelHeaderWrapper
      aria-controls={panelContentId}
      data-testid={dataTestId ? `${dataTestId}-headerwrapper` : undefined}
      aria-expanded={isOpen}
      ref={ref}
      type="button"
      {...other}
    >
      <PanelTitle data-testid={dataTestId ? `${dataTestId}-title` : undefined}>{title}</PanelTitle>
      {renderAfterTitle && renderAfterTitle()}
      <PanelChevron isOpen={isOpen} />
    </PanelHeaderWrapper>
  )
);

PanelHeader.propTypes = {
  /** Unique identifier of the component */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** If true, Panel content will be visible */
  isOpen: PropTypes.bool,
  /** Unique identifier of PanelContent component */
  panelContentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Renders custom content after title */
  renderAfterTitle: PropTypes.func,
  /** Title of Panel */
  title: PropTypes.node.isRequired,
  /** ID attribute to test the node */
  dataTestId: PropTypes.string,
};

PanelHeader.defaultProps = {
  isOpen: false,
  renderAfterTitle: undefined,
  dataTestId: '',
};

export { PanelHeader };
