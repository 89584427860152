import { css } from 'styled-components';

import { focusOutlineColors } from './../core';

export const viewSwitcherButtonStateStyles = {
  active: css`
    background-color: ${({ theme }) => theme.color.state.dark.pressed.value};
  `,
  focus: css`
    background-color: ${({ theme }) => theme.color.state.default.hover.value};
    box-shadow: 0 0 0 4px ${({ theme }) => focusOutlineColors.getDark(theme)};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.state.default.hover.value};
  `,
};
