import styled from 'styled-components';

import { NAVIGATION_WIDTHS } from '../constants';
import { BREAKPOINTS, transitionAnimation } from './../../core';

const SideContentWrapper = styled.div`
  ${transitionAnimation('margin-left')};
  box-sizing: border-box;
  margin-left: ${NAVIGATION_WIDTHS.COLLAPSED};

  @media ${BREAKPOINTS.L} {
    margin-left: ${({ isNavigationCollapsed }) =>
      isNavigationCollapsed ? NAVIGATION_WIDTHS.COLLAPSED : NAVIGATION_WIDTHS.EXPANDED};
  }
`;

export { SideContentWrapper };
