import { useRef } from 'react';

import { END_DATE } from '../constants';

// This custom hook is necessary so that correct month is shown when opening date picker from end date input.
const useInitialVisibleMonth = (endDate) => {
  const initialVisibleMonthRef = useRef(undefined);

  // dateInputType can be START_DATE='startDate', END_DATE='endDate' or undefined
  const setInitialVisibleMonthDateInput = (dateInputType) => {
    const endDateValueIsSelected = !!endDate;

    if (dateInputType === END_DATE && endDateValueIsSelected) {
      initialVisibleMonthRef.current = () => endDate;
    } else {
      initialVisibleMonthRef.current = undefined;
    }
  };

  return [initialVisibleMonthRef.current, setInitialVisibleMonthDateInput];
};

export { useInitialVisibleMonth };
