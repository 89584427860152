import React from 'react';

import { Avatar, AVATAR_COLORS, AVATAR_SIZES, AVATAR_VARIANTS } from 'lib/avatar';
import { removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CardHeader } from './CardHeader';

const StyledAvatar = styled(Avatar)`
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.size.spacing.medium.value};
`;

const CardHeaderWithAvatar = ({ avatarProps, dataTestId, ...other }) => {
  const renderAvatar = () => !!avatarProps && <StyledAvatar {...avatarProps} />;

  const propsWithExcludedOptions = removeObjectProperties(other, 'renderBeforeContent');

  return <CardHeader dataTestId={dataTestId} renderBeforeContent={renderAvatar} {...propsWithExcludedOptions} />;
};

CardHeaderWithAvatar.propTypes = {
  /** Object of properties, which are applied to avatar component */
  avatarProps: PropTypes.shape({
    /** Accessibility measurement for verbal image description */
    alt: PropTypes.node,
    /** Sets background color of avatar component */
    color: PropTypes.oneOf(Object.values(AVATAR_COLORS)),
    /** Outputs icon inside the avatar. Use icon component from the library */
    icon: PropTypes.node,
    /** Username, that this avatar depicts. */
    label: PropTypes.node.isRequired,
    /** Avatar wrapper size. Icon size changes depending on wrapper size */
    size: PropTypes.oneOf(Object.values(AVATAR_SIZES)),
    /** Path to image file */
    src: PropTypes.string,
    /** Defines multiple sizes of the same image, allowing the browser to select the appropriate image source */
    srcset: PropTypes.string,
    /** Avatar variant. Values: [EMPTY, TEXT, ICON, IMAGE]. Default TEXT */
    variant: PropTypes.oneOf(Object.values(AVATAR_VARIANTS)),
  }),
  /** Ability to supply a different element instead of the default one for main wrapper */
  customHeaderTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for subtitle */
  customSubtitleTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for title */
  customTitleTag: PropTypes.elementType,
  /** Renders custom content after main content group */
  renderAfterContent: PropTypes.func,
  /** Renders custom content before main content group */
  renderBeforeContent: PropTypes.func,
  /** Secondary title of the component */
  subtitle: PropTypes.node,
  /** Primary title of the component */
  title: PropTypes.node,
  /** ID attribute to test the node */
  dataTestId: PropTypes.string,
};

CardHeaderWithAvatar.defaultProps = {
  avatarProps: undefined,
  customHeaderTag: undefined,
  customSubtitleTag: undefined,
  customTitleTag: undefined,
  renderAfterContent: undefined,
  renderBeforeContent: undefined,
  subtitle: '',
  title: '',
  dataTestId: '',
};

export { CardHeaderWithAvatar };
