import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const fullscreenStyles = css`
  border-radius: 0;
  height: 100%;
  max-height: none;
  max-width: none;
  width: 100%;
`;

const StyledDialogWrapper = styled.div`
  ${({ isFullScreen, theme }) => css`
    background-color: ${theme.color.additional.light.value};
    border-radius: ${theme.size.borderRadius.large.value};
    box-shadow: ${theme.elevation[4].value};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    left: 50%;
    margin-right: -50%;
    max-height: 80vh;
    max-width: 80vw;
    min-width: 30vw;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: ${theme.zIndex.dialog.value};
    ${isFullScreen && fullscreenStyles}
  `}
`;

const DialogWrapper = (props) => <StyledDialogWrapper role="dialog" aria-modal="true" {...props} />;

DialogWrapper.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node.isRequired,
};

export { DialogWrapper };
