import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { typographyBody1, wordBreak } from './../../core';

const StyledCardContent = styled.div`
  ${({ theme }) => typographyBody1(theme)};
  ${wordBreak};
`;

const CardContent = ({ customTag, ...other }) => <StyledCardContent as={customTag} {...other} />;

CardContent.propTypes = {
  /** Ability to supply a different element instead of the default one */
  customTag: PropTypes.elementType,
};

CardContent.defaultProps = {
  customTag: undefined,
};

export { CardContent };
