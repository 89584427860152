import { useRef } from 'react';

export const useSelectionRange = () => {
  const selectionStartRef = useRef();
  const selectionEndRef = useRef();

  const handleSelect = ({ target: { selectionEnd, selectionStart } }) => {
    selectionStartRef.current = selectionStart;
    selectionEndRef.current = selectionEnd;
  };

  return [selectionStartRef, selectionEndRef, handleSelect];
};
