import React from 'react';

import { AVATAR_COLORS, AVATAR_SIZES, AVATAR_VARIANTS } from 'lib/avatar';
import { BUTTON_VARIANTS, IconButton } from 'lib/button';
import { IconToggle } from 'lib/icon-toggle';
import PropTypes from 'prop-types';

import { CardFooter, CardHeaderWithAvatar, CardMedia } from '../../blocks';
import { CardBody, CardContent, CardGroup, CardWrapper } from '../../elements';

const MediaCard = ({
  avatarProps,
  buttonsProps,
  children,
  customCardTag,
  customContentTag,
  customFooterTag,
  customHeaderTag,
  customSubtitleTag,
  customTitleTag,
  dataTestId,
  iconButtonProps,
  iconToggleProps,
  mediaProps,
  subtitle,
  title,
  ...other
}) => {
  const showButtons = buttonsProps && buttonsProps.length > 0;
  const showContent = !!children;
  const showHeader = !!avatarProps || !!title || !!subtitle;
  const showIconToggle = !!iconToggleProps;
  const showIconButton = !!iconButtonProps;
  const showMedia = !!mediaProps && !!mediaProps.src;
  const showFooter = showButtons || showIconToggle || showIconButton;
  const showCardGroup = showHeader || showContent;
  const showCardBody = showCardGroup || showFooter;

  return (
    <CardWrapper customTag={customCardTag} dataTestId={dataTestId ? `${dataTestId}-media` : undefined} {...other}>
      {showMedia && (
        <CardMedia
          dataTestId={dataTestId ? `${dataTestId}-media` : undefined}
          isRounded={!showCardBody}
          {...mediaProps}
        />
      )}
      {showCardBody && (
        <CardBody>
          {showCardGroup && (
            <CardGroup>
              {showHeader && (
                <CardHeaderWithAvatar
                  avatarProps={avatarProps}
                  customHeaderTag={customHeaderTag}
                  customSubtitleTag={customSubtitleTag}
                  customTitleTag={customTitleTag}
                  subtitle={subtitle}
                  title={title}
                  dataTestId={dataTestId ? `${dataTestId}-header-avatar` : undefined}
                />
              )}
              {showContent && <CardContent customTag={customContentTag}>{children}</CardContent>}
            </CardGroup>
          )}
          {showFooter && (
            <CardFooter
              buttonsProps={buttonsProps}
              customFooterTag={customFooterTag}
              iconButtonProps={iconButtonProps}
              iconToggleProps={iconToggleProps}
            />
          )}
        </CardBody>
      )}
    </CardWrapper>
  );
};

MediaCard.propTypes = {
  ...CardFooter.propTypes,
  /** Object of properties, which are applied to avatar component */
  avatarProps: PropTypes.shape({
    /** Accessibility measurement for verbal image description */
    alt: PropTypes.node,
    /** Sets background color of avatar component */
    color: PropTypes.oneOf(Object.values(AVATAR_COLORS)),
    /** Outputs icon inside the avatar. Use icon component from the library */
    icon: PropTypes.node,
    /** Username, that this avatar depicts. */
    label: PropTypes.node.isRequired,
    /** Avatar wrapper size. Icon size changes depending on wrapper size */
    size: PropTypes.oneOf(Object.values(AVATAR_SIZES)),
    /** Path to image file */
    src: PropTypes.string,
    /** Defines multiple sizes of the same image, allowing the browser to select the appropriate image source */
    srcset: PropTypes.string,
    /** Avatar variant. Values: [EMPTY, TEXT, ICON, IMAGE]. Default TEXT */
    variant: PropTypes.oneOf(Object.values(AVATAR_VARIANTS)),
  }),
  /** Array of object of properties, which is applied to button component */
  buttonsProps: PropTypes.arrayOf(
    PropTypes.shape({
      /** Changes button style depending on variant */
      variant: PropTypes.oneOf([BUTTON_VARIANTS.TEXT, BUTTON_VARIANTS.PRIMARY]),
    })
  ),
  /** Renders any content passed between tags */
  children: PropTypes.node,
  /** Ability to supply an optional custom card element */
  customCardTag: PropTypes.elementType,
  /** Ability to supply an optional custom content element */
  customContentTag: PropTypes.elementType,
  /** Ability to supply an optional custom footer element */
  customFooterTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for main wrapper */
  customHeaderTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for subtitle */
  customSubtitleTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for title */
  customTitleTag: PropTypes.elementType,
  /** Object of properties, which is applied to iconButton component */
  iconButtonProps: PropTypes.shape(IconButton.propTypes),
  /** Object of properties, which is applied to iconToggle component  */
  iconToggleProps: PropTypes.shape(IconToggle.propTypes),
  /** Object of properties, to render media */
  mediaProps: PropTypes.shape({
    /** Accessibility measurement for verbal image description */
    alt: PropTypes.node,
    /** If true, title will be hidden */
    hideTitle: PropTypes.bool,
    /** If true, image will have bottom border radius */
    isRounded: PropTypes.bool,
    /** If true, image will be rendered as square */
    isSquare: PropTypes.bool,
    /** Specifies the URL of an image */
    src: PropTypes.string,
    /** Displays provided value with predefined style */
    title: PropTypes.node,
  }),
  /** Secondary title of the component */
  subtitle: PropTypes.node,
  /** Primary title of the component */
  title: PropTypes.node,
};

MediaCard.defaultProps = {
  ...CardFooter.defaultProps,
  avatarProps: undefined,
  buttonsProps: undefined,
  children: undefined,
  customCard: '',
  customContentTag: '',
  customFooterTag: undefined,
  customHeaderTag: undefined,
  customSubtitleTag: undefined,
  customTitleTag: undefined,
  iconButtonProps: undefined,
  iconToggleProps: undefined,
  mediaProps: undefined,
  renderAfterContent: undefined,
  renderBeforeContent: undefined,
  subtitle: '',
  title: '',
};

export { MediaCard };
