// Validator logic is based on hibernate-validator package's email validator
// eslint-disable-next-line max-len
// https://github.com/hibernate/hibernate-validator/blob/master/engine/src/main/java/org/hibernate/validator/internal/constraintvalidators/AbstractEmailValidator.java
// eslint-disable-next-line max-len
// https://github.com/hibernate/hibernate-validator/blob/master/engine/src/main/java/org/hibernate/validator/internal/util/DomainNameUtil.java

const LOCAL_PART_ATOM = "[a-z0-9!#$%&'*+/=?^_`{|}~\u0080-\uFFFF-]"; // tslint:disable-line
const LOCAL_PART_INSIDE_QUOTES_ATOM = '([a-z0-9!#$%&\'*.(),<>\\[\\]:;  @+/=?^_`{|}~\u0080-\uFFFF-]|\\\\\\\\|\\\\\\")';

const DOMAIN_CHARS_WITHOUT_DASH = "[a-z\u0080-\uFFFF0-9!#$%&'*+/=?^_`{|}~]"; // tslint:disable-line
const DOMAIN_LABEL = `(${DOMAIN_CHARS_WITHOUT_DASH}-*)*${DOMAIN_CHARS_WITHOUT_DASH}+`;
const DOMAIN = `${DOMAIN_LABEL}(\\.${DOMAIN_LABEL})*`;
const IP_DOMAIN = '[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}';
/* eslint-disable */
const IP_V6_DOMAIN =
  '(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))';

export const EMAIL_LOCAL_PART_REGEX = new RegExp(
  `^(${LOCAL_PART_ATOM}+|"${LOCAL_PART_INSIDE_QUOTES_ATOM}+")(\\.(${LOCAL_PART_ATOM}+|"${LOCAL_PART_INSIDE_QUOTES_ATOM}+"))*$`,
  'i'
);
/* eslint-enable */

export const EMAIL_DOMAIN_PART_REGEX = new RegExp(`^(${DOMAIN}|\\[${IP_DOMAIN}\\]|\\[IPv6:${IP_V6_DOMAIN}\\])$`, 'i');

export const ZIP_CODE_REGEX = /^\d{5}$/;
export const PHONE_NUMBER_REGEX = /^([+]?1[\s-]?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/;
export const NOT_NUMBER_REGEX = /[^0-9]/g;
export const NOT_CURRENCY_REGEX = /[^0-9.]/g;
export const ADD_COMMAS_TO_CURRENCY_REGEX = /\B(?=(\d{3})+(?!\d))/g;

const MAX_LOCAL_PART_LENGTH = 64;
const MAX_DOMAIN_PART_LENGTH = 255;

const isLocalPartValid = (localPart) =>
  localPart.length <= MAX_LOCAL_PART_LENGTH && EMAIL_LOCAL_PART_REGEX.test(localPart);

const isDomainPartValid = (domainPart) =>
  domainPart.length <= MAX_DOMAIN_PART_LENGTH && EMAIL_DOMAIN_PART_REGEX.test(domainPart) && domainPart.includes('.');

export const isEmailValid = (email) => {
  if (!email) {
    return true;
  }

  const splitIndex = email.lastIndexOf('@');

  if (splitIndex < 0) {
    return false;
  }

  const localPart = email.substring(0, splitIndex);
  const domainPart = email.substring(splitIndex + 1);

  return isLocalPartValid(localPart) && isDomainPartValid(domainPart);
};
