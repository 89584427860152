import React from 'react';

import { NormalizedButton } from 'lib/button';
import { IconClose } from 'lib/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { iconElementBasePositioning } from '../iconElementBasePositioning';
import { getRem, removeOutline, transitionAnimation, visuallyHidden } from './../../core';

const REMOVE_ICON_SIZE = getRem(14);

const StyledIcon = styled(IconClose).withConfig({
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})`
  ${removeOutline};
  ${transitionAnimation('background-color, fill')};
  background-color: transparent;
  border-radius: ${({ theme }) => theme.size.borderRadius.full.value};
  fill: ${({ isSelected, theme }) =>
    isSelected ? theme.color.primary[600].value : theme.color.text.light.secondary.value};
  height: ${REMOVE_ICON_SIZE};
  padding: ${getRem('2px')};
  width: ${REMOVE_ICON_SIZE};
`;

const StyledRemoveButton = styled(NormalizedButton)`
  ${iconElementBasePositioning};
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  padding: 0 ${getRem('6px')};
  right: 0;

  &:hover,
  &:focus,
  &:active {
    ${StyledIcon} {
      background-color: ${({ isSelected, theme }) =>
        isSelected ? theme.color.primary[600].value : theme.color.text.light.secondary.value};
      fill: ${({ theme }) => theme.color.additional.light.value};
    }
  }
`;

const StyledLabel = styled.span`
  ${visuallyHidden};
`;

const ChipRemoveButton = ({ isSelected, label, onRemove, removeButtonLabel, ...other }) => {
  return (
    <StyledRemoveButton type="button" onClick={onRemove} isSelected={isSelected} {...other}>
      <StyledLabel>{`${removeButtonLabel} ${label}`}</StyledLabel>
      <StyledIcon isSelected={isSelected} />
    </StyledRemoveButton>
  );
};

ChipRemoveButton.propTypes = {
  /** If true, renders input chip specific styles for remove chip button */
  isSelected: PropTypes.bool,
  /** Chip label */
  label: PropTypes.node,
  /** Callback function which is triggered on button click */
  onRemove: PropTypes.func,
  /** Text which is read for screen reader users, when remove button is focused */
  removeButtonLabel: PropTypes.node,
};

ChipRemoveButton.defaultProps = {
  label: 'Chip',
  onRemove: undefined,
  removeButtonLabel: 'Remove',
};

export { ChipRemoveButton };
