export const CURRENCIES = {
  CAD: 'cad',
  CNY: 'cny',
  EUR: 'eur',
  FR_CA: 'fr-ca',
  GBP: 'gbp',
  INR: 'inr',
  JPY: 'jpy',
  MXN: 'mxn',
  USD: 'usd',
};

export const CURRENCY_PREFIXES = {
  [CURRENCIES.CAD]: '$',
  [CURRENCIES.CNY]: '¥',
  [CURRENCIES.EUR]: '€',
  [CURRENCIES.GBP]: '£',
  [CURRENCIES.INR]: '₹',
  [CURRENCIES.JPY]: '¥',
  [CURRENCIES.MXN]: '$',
  [CURRENCIES.USD]: '$',
  [CURRENCIES.FR_CA]: '$',
};

export const PRECISION = {
  [CURRENCIES.CAD]: 2,
  [CURRENCIES.CNY]: 2,
  [CURRENCIES.EUR]: 2,
  [CURRENCIES.GBP]: 2,
  [CURRENCIES.INR]: 2,
  [CURRENCIES.JPY]: 0,
  [CURRENCIES.MXN]: 2,
  [CURRENCIES.USD]: 2,
  [CURRENCIES.FR_CA]: 2,
};
