import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { LIST_SIZES } from '../../constants';
import { BREAKPOINTS, getRem, truncateText, typographyBody1, typographyBody2 } from './../../../core';

const StyledListItemMetadata = styled.div`
  ${truncateText};
  ${({ theme }) => typographyBody2(theme)};
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.text.light.secondary.value};
  display: inline-flex;
  flex-shrink: 0;
  justify-content: flex-end;
  padding-left: ${getRem(24)};
  pointer-events: none;
  ${({ size, theme }) =>
    size === LIST_SIZES.STANDARD &&
    css`
      @media ${BREAKPOINTS.L} {
        ${typographyBody1(theme)}
        color: ${theme.color.text.light.secondary.value};
      }
    `}

  /* Currently, there is no other way to apply svg styles as the content can be either text or any DOM element */
    svg {
    fill: ${({ theme }) => theme.color.text.light.disabled.value};
    height: ${getRem(24)};
    width: ${getRem(24)};
  }
`;

const ListItemMetadata = (props) => <StyledListItemMetadata {...props} />;

ListItemMetadata.propTypes = {
  /** Changes appearance on different sizes */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
};

ListItemMetadata.defaultProps = {
  size: LIST_SIZES.STANDARD,
};

export { ListItemMetadata };
