import styled, { css } from 'styled-components';

import { getLineHeight, getRem, typographyBody1 } from './../../core';
import { ie11ReadOnlyStyles, INPUT_SIZES } from './../../input';

const TextAreaField = styled.textarea.withConfig({
  shouldForwardProp: (prop) => !['isReadOnly', 'size', 'hideLabel', 'maxRows'].includes(prop),
})`
  ${({ theme }) => typographyBody1(theme)};
  background-color: transparent;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  outline: none;
  overflow: auto;
  padding: 0 ${getRem('12px')} 0;
  resize: none;

  &::placeholder {
    color: ${({ theme }) => theme.color.text.light.disabled.value};
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ size }) => css`
    ${size === INPUT_SIZES.LARGE &&
    css`
      font-size: ${getRem('16px')};
      line-height: ${getLineHeight('24px', '16px')};
    `}
  `}
  ${({ isReadOnly }) => isReadOnly && ie11ReadOnlyStyles}
`;

export { TextAreaField };
