import React from 'react';
import PropTypes from 'prop-types';

import { PageHeaderTitle, PageHeaderWrapper } from '../elements';

const PageHeaderTitleBlock = ({ title, ...other }) => (
  <PageHeaderWrapper {...other}>
    <PageHeaderTitle>{title}</PageHeaderTitle>
  </PageHeaderWrapper>
);

PageHeaderTitleBlock.propTypes = {
  title: PropTypes.node.isRequired,
};

export { PageHeaderTitleBlock };
