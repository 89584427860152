import React from 'react';
import { removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import { MASKABLE_FORMAT_CHARS } from '../constants';
import { Input, INPUT_ICON_POSITIONS, INPUT_SIZES } from './../../../input';

// maxLength, minLength props breaks masking because length is limited by the InputMask component
const MaskedInput = React.forwardRef((props, ref) => (
  <InputMask ref={ref} {...removeObjectProperties(props, 'maxLength', 'minLength')} />
));

const MaskableFormattedInput = React.forwardRef(({ customTag, mask, ...other }, ref) => {
  const isOnlyNum = /^\d+$/.test(mask);
  const reactInputMaskProps = {
    formatChars: MASKABLE_FORMAT_CHARS,
    mask,
    maskChar: '',
    inputMode: isOnlyNum ? 'numeric' : 'text',
  };

  const Tag = customTag || Input;

  return <Tag as={MaskedInput} customInputElement={MaskedInput} ref={ref} {...reactInputMaskProps} {...other} />;
});

MaskableFormattedInput.propTypes = {
  /** Ability to supply a different input element instead of the default one */
  customTag: PropTypes.elementType,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, custom validation is being enabled instead of built in component validation */
  enableCustomValidation: PropTypes.bool,
  /** Message to be displayed when input is in error state */
  errorMessage: PropTypes.node,
  /** When true, input is in error state */
  hasError: PropTypes.bool,
  /** Text to be displayed as a helper text near the input field */
  helperText: PropTypes.node,
  /** Icon to be displayed in input field */
  icon: PropTypes.node,
  /** Sets the position of icon */
  iconPosition: PropTypes.oneOf(Object.values(INPUT_ICON_POSITIONS)),
  /** Identifier of the input component */
  id: PropTypes.string.isRequired,
  /* If true, input is disabled and its value cannot be edited */
  isDisabled: PropTypes.bool,
  /** If true, input is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, isRequired asterisk will be shown */
  isRequired: PropTypes.bool,
  /** Label of the input field */
  label: PropTypes.node.isRequired,
  /** Specifies the mask format for the input */
  mask: PropTypes.string.isRequired,
  /** Name of the input */
  name: PropTypes.string.isRequired,
  /** Callback to be called when input's value is being changed by user interaction */
  onChange: PropTypes.func,
  /** Text to be displayed when input is empty */
  placeholder: PropTypes.node,
  /** String to be displayed before the input value. Prefix text should be 1 character. */
  prefixText: PropTypes.node,
  /** Set the size of the input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  /** String to be displayed after the input value. Text should contain up to 5 characters, to not get cutted */
  suffixText: PropTypes.node,
  /** Current value of the input field */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

MaskableFormattedInput.defaultProps = {
  customTag: undefined,
  dataTestId: undefined,
  enableCustomValidation: false,
  errorMessage: '',
  hasError: false,
  helperText: '',
  icon: undefined,
  iconPosition: INPUT_ICON_POSITIONS.TRAILING,
  isDisabled: false,
  isReadOnly: false,
  isRequired: false,
  onChange: () => {},
  placeholder: '',
  prefixText: '',
  size: INPUT_SIZES.STANDARD,
  suffixText: '',
};

export { MaskableFormattedInput };
