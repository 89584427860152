import React from 'react';
import objectFitImages from 'object-fit-images';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem } from './../../core';

const StyledCardMediaImageWrapper = styled.figure`
  background-color: ${({ theme }) => theme.color.gray[300].value};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  display: flex;
  flex-shrink: 0;
  height: ${getRem('180px')};
  margin: 0;
  position: relative;
  ${({ isSquare }) =>
    isSquare &&
    css`
      height: auto;

      &::after {
        content: '';
        display: block;
        padding-top: 100%;
      }
    `};
  ${({ isRounded }) =>
    isRounded &&
    css`
      border-radius: inherit;
    `};
`;

const StyledCardMediaImage = styled.img`
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  /* stylelint-disable */
  font-family: 'object-fit: cover'; /* Necessary for objectFitImages plugin work correctly  */
  /* stylelint-enable */
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  width: 100%;
  ${({ isRounded }) =>
    isRounded &&
    css`
      border-radius: inherit;
    `};
`;

// applies object-fit polyfill
objectFitImages();

const CardMediaImage = ({ alt, children, dataTestId, isRounded, isSquare, src, ...other }) => (
  <StyledCardMediaImageWrapper isRounded={isRounded} isSquare={isSquare} {...other}>
    {!!src && <StyledCardMediaImage alt={alt} isRounded={isRounded} data-testid={dataTestId} src={src} />}
    {children}
  </StyledCardMediaImageWrapper>
);

CardMediaImage.propTypes = {
  /** Accessibility measurement for verbal image description */
  alt: PropTypes.node,
  /** Any content inserted between component tags. Content should be overflowing */
  children: PropTypes.node,
  /** If true, image will have bottom border radius */
  isRounded: PropTypes.bool,
  /** If true, image will be rendered as square */
  isSquare: PropTypes.bool,
  /** Specifies the URL of an image */
  src: PropTypes.string,
  /** ID for testing the node */
  dataTestId: PropTypes.string,
};

CardMediaImage.defaultProps = {
  alt: '',
  children: undefined,
  isRounded: false,
  isSquare: false,
  src: '',
  dataTestId: '',
};

export { CardMediaImage };
