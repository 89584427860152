import React from 'react';

import { removeObjectProperties } from 'lib/utilities';

import { PanelHeader } from '../blocks';
import { PanelContent } from '../elements';
import { Accordion } from './Accordion';

const SimpleAccordion = (props) => {
  const propsWithoutPanelTags = removeObjectProperties(props, 'panelContentComponent', 'panelHeaderComponent');
  return (
    <Accordion panelContentComponent={PanelContent} panelHeaderComponent={PanelHeader} {...propsWithoutPanelTags} />
  );
};

export { SimpleAccordion };
