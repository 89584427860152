import { css } from 'styled-components';

import { BUTTON_BACKGROUND_APPEARANCES } from '../../constants';
import { getButtonContentElementsColor } from '../../shared-styles';
import { focusOutlinesStyles } from './../../../core';

export const textButtonStateStyles = {
  default: {
    active: css`
      background-color: ${({ theme }) => theme.color.state.primary.pressed.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.primary[700].value)};
    `,
    default: css`
      ${({ theme }) => getButtonContentElementsColor(theme.color.primary[500].value)};
    `,
    disabled: css`
      ${({ theme }) => getButtonContentElementsColor(theme.color.text.light.disabled.value)};
    `,
    focus: css`
      background-color: ${({ theme }) => theme.color.state.primary.focus.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.primary[600].value)};
      ${focusOutlinesStyles.primary};
    `,
    hover: css`
      background-color: ${({ theme }) => theme.color.state.primary.hover.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.primary[600].value)};
    `,
  },
  defaultOnPrimaryBackground: {
    active: css`
      background-color: ${({ theme }) => theme.color.state.dark.pressed.value};
    `,
    default: css`
      ${({ theme }) => getButtonContentElementsColor(theme.color.additional.light.value)};
    `,
    disabled: css`
      ${({ theme }) => getButtonContentElementsColor(theme.color.text.dark.disabled.value)};
    `,
    focus: css`
      background-color: ${({ theme }) => theme.color.state.dark.focus.value};
      ${focusOutlinesStyles.light};
    `,
    hover: css`
      background-color: ${({ theme }) => theme.color.state.dark.hover.value};
    `,
  },
};

export const getStateStyles = (backgroundAppearance) => {
  return backgroundAppearance === BUTTON_BACKGROUND_APPEARANCES.LIGHT
    ? textButtonStateStyles.default
    : textButtonStateStyles.defaultOnPrimaryBackground;
};
