import React from 'react';

import { NormalizedButton } from 'lib/button';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { TAB_ALIGNMENTS } from '../constants';
import { TabTitle } from '../elements';
import { getStateStyles } from '../tabStateStyles';
import {
  ElementStateWrapper,
  getHexToRgb,
  getRem,
  transitionAnimation,
  truncateText,
  visuallyHidden,
} from './../../core';

const unselectedStyles = css`
  ${transitionAnimation('background-color')};
  background-color: rgba(${({ theme }) => getHexToRgb(theme.color.gray[100].value)}, 0.92);
  position: relative;

  &::after {
    ${transitionAnimation('background-color')};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:hover,
  &:focus {
    &::after {
      background-color: ${({ theme }) => theme.color.state.default.hover.value};
    }
  }

  &:active {
    &::after {
      background-color: ${({ theme }) => theme.color.state.default.pressed.value};
    }
  }
`;

const StyledIconWrapper = styled.span`
  display: block;
  fill: ${({ theme }) => theme.color.text.light.secondary.value};
  height: ${getRem(28)};
`;

const StyledTabTitle = styled(TabTitle)`
  display: block;
  ${({ hideLabel }) => hideLabel && visuallyHidden}
`;

const StyledTab = styled(NormalizedButton)`
  ${truncateText};
  background-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.light.value)}, 0.92);
  cursor: pointer;
  padding: ${({ theme }) => theme.size.spacing.large.value};
  padding-top: ${({ hideLabel, icon, theme }) =>
    icon && !hideLabel ? theme.size.spacing.medium.value : theme.size.spacing.large.value};
  text-align: center;
  ${({ alignment }) =>
    alignment === TAB_ALIGNMENTS.JUSTIFIED &&
    css`
      width: 100%;
    `}
  ${({ isActive }) => !isActive && unselectedStyles};
`;

const StyledTabWrapper = styled(ElementStateWrapper)`
  display: inline;
`;

const Tab = React.forwardRef(({ hideLabel, icon, isActive, label, onClick, onFocus, ...other }, ref) => {
  const handleClick = (event) => {
    event.target.focus();
    onClick(event);
  };

  return (
    <StyledTabWrapper onFocus={onFocus} stateStyles={getStateStyles(isActive)} tabIndex={isActive ? 0 : -1} {...other}>
      <StyledTab
        hideLabel={hideLabel}
        isActive={isActive}
        icon={icon}
        onClick={handleClick}
        showOutline
        type="button"
        ref={ref}
      >
        {!!icon && <StyledIconWrapper>{icon}</StyledIconWrapper>}
        <StyledTabTitle isActive={isActive} hideLabel={hideLabel}>
          {label}
        </StyledTabTitle>
      </StyledTab>
    </StyledTabWrapper>
  );
});

Tab.propTypes = {
  /** Visually adjusts tab alignment */
  alignment: PropTypes.oneOf(Object.values(TAB_ALIGNMENTS)),
  /** If true, visually hides tab label */
  hideLabel: PropTypes.bool,
  /** Outputs icon inside the tab. Use icon as a component */
  icon: PropTypes.node,
  /** Tab identifier */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** If true, shows tab as active */
  isActive: PropTypes.bool,
  /** Label of Tab */
  label: PropTypes.node.isRequired,
  /** Callback function which is triggered on tab click */
  onClick: PropTypes.func.isRequired,
  /** Callback function which is triggered on tab focus */
  onFocus: PropTypes.func.isRequired,
};

Tab.defaultProps = {
  alignment: TAB_ALIGNMENTS.JUSTIFIED,
  hideLabel: false,
  icon: undefined,
  isActive: false,
};

export { Tab };
