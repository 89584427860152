import React from 'react';

import { BUTTON_BACKGROUND_APPEARANCES, TextButton } from 'lib/button';
import { IconHelp } from 'lib/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { footerButtonStyles } from '../shared-styles';
import { getRem } from './../../core';

const StyledHelpButton = styled(TextButton)`
  ${footerButtonStyles};
  box-sizing: border-box;
  max-width: ${getRem(196)};
  position: absolute;
  text-align: left;
  top: ${({ theme }) => theme.size.spacing.medium.value};

  && {
    &:hover,
    &:focus {
      box-shadow: none;

      &::after {
        background-color: ${({ theme }) => theme.color.state.light.hover.value};
      }
    }
  }
`;

const HelpButton = ({ icon, isCollapsed, label, onClick, ...other }) => (
  <StyledHelpButton
    backgroundAppearance={BUTTON_BACKGROUND_APPEARANCES.DARK}
    hideText={isCollapsed || !label}
    icon={icon}
    onClick={onClick}
    text={label}
    {...other}
  />
);

HelpButton.propTypes = {
  /** Outputs icon inside the help button. Use icon component from the library */
  icon: PropTypes.node,
  /** If true, component is collapsed */
  isCollapsed: PropTypes.bool,
  /** Help button label */
  label: PropTypes.node.isRequired,
  /** Callback that is called on click */
  onClick: PropTypes.func,
};

HelpButton.defaultProps = {
  icon: <IconHelp />,
  isCollapsed: false,
  onClick: undefined,
};

export { HelpButton };
