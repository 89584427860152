import { flatMap } from 'lib/utilities';

const findItemInChildren = (item, itemId) => {
  const hasChildren = item.items && item.items.length > 0;

  const itemsFoundInChildren = hasChildren
    ? flatMap(item.items, (childItem) => findItemInChildren(childItem, itemId))
    : [];

  return item.id === itemId ? [item, ...itemsFoundInChildren] : itemsFoundInChildren;
};

export const findItemById = (items, itemId) => {
  const itemsFound = flatMap(items, (item) => findItemInChildren(item, itemId));

  return itemsFound.length > 0 ? itemsFound[0] : null;
};
