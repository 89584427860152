import React from 'react';

import { NormalizedButton } from 'lib/button';
import { TabTitle } from 'lib/tabs';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { pageHeaderNavigationTabStateStyles } from '../pageHeaderNavigationTabStateStyles';
import { ElementStateWrapper, getRem, transitionAnimation } from './../../core';

const StyledPageHeaderNavigationTab = styled(NormalizedButton)`
  cursor: pointer;
  padding: ${({ theme }) => `${theme.size.spacing.small.value} ${theme.size.spacing.large.value} ${getRem(14)}`};
  white-space: nowrap;

  &::after {
    ${transitionAnimation('background-color, width')}
    background-color: ${({ isActive, theme }) =>
      isActive ? theme.color.additional.light.value : theme.color.primary[300].value};
    content: '';
    display: block;
    height: ${getRem(2)};
    width: ${({ isActive }) => (isActive ? '100%' : '0')};
  }

  &:hover,
  &:focus {
    &::after {
      background-color: ${({ isActive, theme }) =>
        isActive ? theme.color.additional.light.value : theme.color.primary[300].value};
      width: 100%;
    }
  }
`;

const StyledTabTitle = styled(TabTitle)`
  color: ${({ theme }) => theme.color.additional.light.value};
`;

const StyledTabWrapper = styled(ElementStateWrapper)`
  display: inline;
`;

const PageHeaderNavigationTab = React.forwardRef(({ isActive, label, onFocus, ...other }, ref) => {
  return (
    <StyledTabWrapper
      onFocus={onFocus}
      shouldPreventDefault
      stateStyles={pageHeaderNavigationTabStateStyles}
      tabIndex={isActive ? 0 : -1}
      {...other}
    >
      <StyledPageHeaderNavigationTab isActive={isActive} ref={ref} showOutline>
        <StyledTabTitle>{label}</StyledTabTitle>
      </StyledPageHeaderNavigationTab>
    </StyledTabWrapper>
  );
});

PageHeaderNavigationTab.propTypes = {
  /** Tab identifier */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** If true, shows tab as active */
  isActive: PropTypes.bool,
  /** Label of Tab */
  label: PropTypes.node.isRequired,
  /** Callback function which is triggered on tab click */
  onClick: PropTypes.func,
  /** Callback function which is triggered on tab focus */
  onFocus: PropTypes.func.isRequired,
};

PageHeaderNavigationTab.defaultProps = {
  isActive: false,
  onClick: () => {},
};

export { PageHeaderNavigationTab };
