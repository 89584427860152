import React from 'react';

import { LIST_SIZES } from 'lib/list';
import PropTypes from 'prop-types';

import { MENU_HORIZONTAL_POSITIONS, MENU_VERTICAL_POSITIONS, SELECT_ALL } from '../../constants';
import { countAllOptions, getFlattenedOptions, getOptionsWithSelectedValues } from '../../utilities';
import { ContextMenuContent } from '../ContextMenu/ContextMenuContent';

export const MultiSelectContextMenuContent = ({
  ariaLabel,
  className,
  hideGroupLabel,
  isKeyboardEventLatest,
  onClose,
  onSelect,
  options,
  positionHorizontal,
  positionVertical,
  selectAllLabel,
  selectedValues,
  size,
  style,
  wrappedComponentRef,
  ...other
}) => {
  const isAnySelected = selectedValues.length > 0;
  const isAllSelected = countAllOptions(options) === selectedValues.length;
  const latestOptionIndex = options.findIndex((option) => option.value === selectedValues[0]);
  const latestSelectedOptionIndex = latestOptionIndex === -1 ? -1 : latestOptionIndex + 1;

  const selectAllOption = {
    id: SELECT_ALL.ID,
    isIndeterminate: isAnySelected && !isAllSelected,
    isSelected: isAnySelected,
    label: selectAllLabel,
  };

  const handleSelectAll = () => {
    if (!isAnySelected) {
      const optionsExcludingDisabled = getFlattenedOptions(options)
        .filter((option) => !option.isDisabled)
        .map((option) => option.value);

      onSelect(optionsExcludingDisabled);
      return;
    }

    onSelect([]);
  };

  const handleSelect = (selectedOption) => {
    if (selectedOption.id === SELECT_ALL.ID) {
      handleSelectAll();
      return;
    }

    const selectedValuesWithoutSelectedOption = selectedValues.filter((value) => value !== selectedOption.value);

    const isAlreadySelected = selectedValuesWithoutSelectedOption.length !== selectedValues.length;

    if (isAlreadySelected) {
      onSelect(selectedValuesWithoutSelectedOption);
    } else {
      const updatedSelectedValues = [selectedOption.value, ...selectedValues];
      onSelect(updatedSelectedValues);
    }
  };

  const optionsWithSelectedValues = getOptionsWithSelectedValues(options, selectedValues);
  const optionsWithSelectAllOption = [{ label: '', options: [selectAllOption] }, ...optionsWithSelectedValues];

  return (
    <ContextMenuContent
      ariaLabel={ariaLabel}
      className={className}
      hideGroupLabel={hideGroupLabel}
      isMulti
      isKeyboardEventLatest={isKeyboardEventLatest}
      onClose={onClose}
      onSelect={handleSelect}
      options={optionsWithSelectAllOption}
      positionHorizontal={positionHorizontal}
      positionVertical={positionVertical}
      selectedOptionIndex={latestSelectedOptionIndex}
      size={size}
      style={style}
      wrappedComponentRef={wrappedComponentRef}
      {...other}
    />
  );
};

MultiSelectContextMenuContent.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** Sets additional class to the component */
  className: PropTypes.string,
  /** If true, visually hides group label */
  hideGroupLabel: PropTypes.bool,
  /** Tells if keyboard event happened later than mouse event */
  isKeyboardEventLatest: PropTypes.bool,
  /** Callback that is called when context menu is being closed */
  onClose: PropTypes.func.isRequired,
  /** Callback that is called when an item is clicked in the context menu */
  onSelect: PropTypes.func.isRequired,
  /** Context menu options. For correct data structure refer to component documentation */
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Specifies horizontal context menu position relative to wrapper component */
  positionHorizontal: PropTypes.oneOf(Object.values(MENU_HORIZONTAL_POSITIONS)),
  /** Specifies vertical context menu position relative to wrapper component */
  positionVertical: PropTypes.oneOf(Object.values(MENU_VERTICAL_POSITIONS)),
  /** Specifies label text for Select All Option */
  selectAllLabel: PropTypes.node,
  /** Currently selected values of multi select options list */
  selectedValues: PropTypes.arrayOf(PropTypes.node),
  /** Changes list item height */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
  /** Custom inline style applied for the main wrapper */
  style: PropTypes.shape({}),
  /** A reference of the component which opens or closes context menu */
  wrappedComponentRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

MultiSelectContextMenuContent.defaultProps = {
  ariaLabel: 'Select options:',
  className: '',
  hideGroupLabel: false,
  isKeyboardEventLatest: false,
  positionHorizontal: MENU_HORIZONTAL_POSITIONS.RIGHT,
  positionVertical: MENU_VERTICAL_POSITIONS.TOP,
  selectAllLabel: SELECT_ALL.NAME,
  selectedValues: [],
  size: LIST_SIZES.STANDARD,
  style: {},
  wrappedComponentRef: null,
};
