import { css } from 'styled-components';

import { getHexToRgb, getRem } from './../core';

const visitedState = css`
  &:visited {
    color: #609;
  }
`;

export const linkStateStyles = {
  active: css`
    color: ${({ theme }) => theme.color.primary['900'].value};
  `,
  default: css`
    color: ${({ theme }) => theme.color.primary['500'].value};
    padding: ${getRem(2)};
    ${visitedState}
  `,
  disabled: css`
    color: ${({ theme }) => theme.color.text.light.disabled.value};
    cursor: not-allowed;
  `,
  focus: css`
    border-radius: ${({ theme }) => getRem(theme.size.borderRadius.extraSmall.value)};
    box-shadow: 0 0 0 2px rgba(${({ theme }) => getHexToRgb(theme.color.primary[500].value)}, 0.5);
    color: ${({ theme }) => theme.color.primary['700'].value};
    text-decoration: underline;
    ${visitedState}
  `,
  hover: css`
    color: ${({ theme }) => theme.color.primary['700'].value};
    text-decoration: underline;
    ${visitedState}
  `,
};
