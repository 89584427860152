import React from 'react';
import { BUTTON_VARIANTS } from 'lib/button';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { TOAST_POSITIONS, TOAST_VARIANTS, TOAST_VISIBILITY_DURATIONS } from '../constants';
import { BREAKPOINTS, getRem } from './../../core';

import { Toast } from './Toast';

const StyledMultiToast = styled.div.withConfig({
  shouldForwardProp: (prop) => !['position'].includes(prop),
})`
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndex.toast.value};
  ${({ position }) =>
    position === TOAST_POSITIONS.FIXED &&
    css`
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      left: ${({ theme }) => theme.size.spacing.medium.value};
      position: fixed;
      right: ${({ theme }) => theme.size.spacing.medium.value};
      top: ${({ theme }) => theme.size.spacing.medium.value};
      width: auto;

      @media ${BREAKPOINTS.L} {
        left: auto;
        max-width: ${getRem('372px')};
        width: 100%;
      }
    `}
`;

const MultiToasts = React.forwardRef(({ items, position, ...other }, ref) =>
  items.length ? (
    <StyledMultiToast ref={ref} position={position} {...other}>
      {items.map((item) => (
        <Toast key={item.id} {...item} isMulti position={position} />
      ))}
    </StyledMultiToast>
  ) : null
);

MultiToasts.propTypes = {
  /** Array of toast component props */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** Array of action elements. Action element contains Button component properties.
       * A maximum of 2 actions is allowed. */
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          /** Changes button style depending on variant */
          variant: PropTypes.oneOf([BUTTON_VARIANTS.PRIMARY, BUTTON_VARIANTS.TEXT]),
        })
      ),
      /** The content of the component */
      content: PropTypes.node.isRequired,
      /** Unique id for component */
      id: PropTypes.string.isRequired,
      /** Toast visibility handler when visibility duration ends */
      onVisibilityDurationEnd: PropTypes.func,
      /** Inline styles applied to main component wrapper */
      style: PropTypes.shape({}),
      /** Changes toast style depending on variant. */
      variant: PropTypes.oneOf(Object.values(TOAST_VARIANTS)),
      /** Duration of time for how long component will be visible */
      visibilityDuration: PropTypes.oneOf(Object.values(TOAST_VISIBILITY_DURATIONS)),
    })
  ),
  /** Sets components position accordingly to specified value */
  position: PropTypes.oneOf(Object.values(TOAST_POSITIONS)),
};

MultiToasts.defaultProps = {
  items: [],
  position: TOAST_POSITIONS.INLINE,
};

export { MultiToasts };
