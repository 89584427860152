import { useRef } from 'react';

import { useEventListener } from './useEventListener';

export const useIsKeyPressed = (keyCode) => {
  const isKeyDownRef = useRef(false);
  const handleKeyDown = (event) => {
    const keyCodeDown = event.keyCode || event.which || 0;
    if (keyCodeDown === keyCode) {
      isKeyDownRef.current = true;
    }
  };

  const handleKeyUp = (event) => {
    const keyCodeUp = event.keyCode || event.which || 0;
    if (keyCodeUp === keyCode) {
      isKeyDownRef.current = false;
    }
  };

  useEventListener('keydown', handleKeyDown);
  useEventListener('keyup', handleKeyUp);

  return isKeyDownRef;
};
