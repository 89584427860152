import { mapString, filterString } from 'lib/utilities';

import { FORMATTING_CHARACTERS } from '../../regexes';

const replaceWithOverwriting = (value, targetValue) =>
  mapString(value, (x, index) => (targetValue[index] === '●' ? x : targetValue[index]));

export const replaceHiddenWithValue = (value, targetValue) => {
  const shouldOverwrite = value.length === targetValue.length;

  if (shouldOverwrite) {
    return replaceWithOverwriting(value, targetValue);
  }

  let result = '';
  let valueIndex = 0;

  const unformattedValue = filterString(value, (valueCharacter) => !FORMATTING_CHARACTERS.includes(valueCharacter));

  for (let i = 0; i < targetValue.length; i += 1) {
    if (targetValue[i] === '●') {
      result += unformattedValue[valueIndex] || '';
      valueIndex += 1;
    } else {
      result += targetValue[i];
    }
  }

  return result;
};
