import React from 'react';
import {
  IconBookmark,
  IconBookmarkBorder,
  IconFavorite,
  IconFavoriteBorder,
  IconStar,
  IconStarBorder,
} from 'lib/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ICON_TOGGLE_ICON_TYPE } from '../constants';

const StyledIcon = styled(IconFavoriteBorder)`
  flex-shrink: 0;
  pointer-events: none;
`;

const IconToggleIcon = ({ checked, iconType, ...other }) => {
  const getIcon = () => {
    switch (iconType) {
      case ICON_TOGGLE_ICON_TYPE.BOOKMARK:
        return checked ? IconBookmark : IconBookmarkBorder;
      case ICON_TOGGLE_ICON_TYPE.STAR:
        return checked ? IconStar : IconStarBorder;
      default:
        return checked ? IconFavorite : IconFavoriteBorder;
    }
  };

  return <StyledIcon as={getIcon()} {...other} />;
};

IconToggleIcon.propTypes = {
  /** If true, icon visually gets filled */
  checked: PropTypes.bool,
  /** Changes icon which is being displayed */
  iconType: PropTypes.oneOf(Object.values(ICON_TOGGLE_ICON_TYPE)),
};

IconToggleIcon.defaultProps = {
  checked: false,
  iconType: ICON_TOGGLE_ICON_TYPE.FAVORITE,
};

export { IconToggleIcon };
