import { css } from 'styled-components';

import { getHexToRgb } from './../../core';

export const footerButtonStyles = css`
  background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.15)`};
  z-index: ${({ theme }) => theme.zIndex.globalNavigationFooter.value};

  &:not(:active):not(:hover):not(:focus) {
    /* No other way to interact with icon */
    svg {
      fill: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.7)`};
    }
  }
`;
