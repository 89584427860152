export const STRENGTH_RULES = [
  'At least 8 characters',
  'An uppercase letter',
  'A lowercase letter',
  'A number',
  'A symbol (!, @, $ etc.)',
];

export const REGEX_EXPRESSIONS = {
  UPPERCASE: /([A-Z])/,
  LOWERCASE: /([a-z])/,
  SYMBOL: /([!,%,&,@,#,$,^,*,?,_,~])/,
  NUMBER: /([0-9])/,
};

export const MIN_ALLOWED_LENGTH = 7;
