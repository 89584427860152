import { IconChevronRight } from 'lib/icons';
import styled, { css } from 'styled-components';

import { getRem, transitionAnimation } from './../../../core';

const NestedListItemIcon = styled(IconChevronRight).withConfig({
  shouldForwardProp: (prop) => !['isDisabled', 'isExpanded', 'nestingLevel', 'withExtraMargin'].includes(prop),
})`
  fill: ${({ theme }) => theme.color.text.light.secondary.value};
  margin-right: ${({ nestingLevel, withExtraMargin }) =>
    (nestingLevel === 1 && withExtraMargin && getRem('6px')) || (withExtraMargin && getRem('12px')) || 0};
  min-width: ${getRem('24px')};
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(90deg)' : 'none')};
  ${transitionAnimation('all')}

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      fill: ${theme.color.text.light.disabled.value};
    `};
`;

export { NestedListItemIcon };
