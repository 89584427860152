import { cloneElement } from 'react';
import styled from 'styled-components';

export const LinkIcon = styled(({ children, ...props }) => cloneElement(children, props)).withConfig({
  shouldForwardProp: (prop) => !['isIconLeading'].includes(prop),
})`
  align-self: center;
  flex-shrink: 0;
  margin: auto;
`;
