import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PageHeaderIconLink } from '../blocks';
import { PageHeaderTitle, PageHeaderWrapper } from '../elements';

const StyledTitleWrapper = styled.div`
  align-content: center;
  display: flex;
  padding-left: ${({ theme }) => theme.size.spacing.large.value};
`;

const StyledPageHeaderTitle = styled(PageHeaderTitle)`
  padding-left: 0;
`;

const PageHeaderTitleWithLink = ({ dataTestId, icon, linkHref, linkText, title, ...other }) => (
  <PageHeaderWrapper data-testid={dataTestId}>
    <StyledTitleWrapper>
      <PageHeaderIconLink
        data-testid={dataTestId ? `${dataTestId}-icon-link` : undefined}
        href={linkHref}
        icon={icon}
        text={linkText}
        {...other}
      />
      <StyledPageHeaderTitle>{title}</StyledPageHeaderTitle>
    </StyledTitleWrapper>
  </PageHeaderWrapper>
);

PageHeaderTitleWithLink.propTypes = {
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Renders specified icon */
  icon: PropTypes.node.isRequired,
  /** Specifies the URL of the page the link goes to */
  linkHref: PropTypes.string.isRequired,
  /** Specifies the label of the link */
  linkText: PropTypes.node.isRequired,
  /** Specifies the page title */
  title: PropTypes.node.isRequired,
};

PageHeaderTitleWithLink.defaultProps = {
  dataTestId: undefined,
};

export { PageHeaderTitleWithLink };
