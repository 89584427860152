import styled from 'styled-components';

const ChipWrapper = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  min-width: 0;
  position: relative;
`;

export { ChipWrapper };
