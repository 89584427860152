import React from 'react';

import { KEY_CODES } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { LINK_ICON_POSITIONS } from '../constants';
import { LinkIcon } from '../elements';
import { linkStateStyles } from '../linkStateStyles';
import { ElementStateWrapper, getRem, typographyLink } from './../../core';

const StyledLink = styled.a.withConfig({
  shouldForwardProp: (prop) => !['isDisabled', 'isIconLeading', 'isInline'].includes(prop),
})`
  ${({ theme }) => typographyLink(theme)};
  align-items: center;
  display: inline-flex;
  fill: currentColor;
  flex-direction: ${({ isIconLeading }) => (isIconLeading ? 'row-reverse' : 'row')};
  text-decoration: none;

  ${LinkIcon} {
    margin-left: ${({ isIconLeading }) => !isIconLeading && getRem(2)};
    margin-right: ${({ isIconLeading }) => isIconLeading && getRem(2)};
  }

  ${({ isInline }) =>
    isInline &&
    css`
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
      line-height: inherit;
    `}
`;

const triggerKeys = [KEY_CODES.ENTER];

const Link = React.forwardRef(({ customTag, href, icon, iconPosition, isDisabled, isInline, text, ...other }, ref) => {
  const anchorHref = isDisabled ? undefined : href;
  const isIconLeading = iconPosition === LINK_ICON_POSITIONS.LEADING;

  return (
    <ElementStateWrapper stateStyles={linkStateStyles} isDisabled={isDisabled} triggerKeys={triggerKeys} {...other}>
      <StyledLink
        aria-disabled={isDisabled}
        as={customTag}
        data-testid="link"
        href={anchorHref}
        isDisabled={isDisabled}
        isIconLeading={isIconLeading}
        isInline={isInline}
        ref={ref}
      >
        {text}
        {!!icon && <LinkIcon isIconLeading={isIconLeading}>{icon}</LinkIcon>}
      </StyledLink>
    </ElementStateWrapper>
  );
});

Link.propTypes = {
  /** Replaces anchor tag with a new one */
  customTag: PropTypes.elementType,
  /** Specifies the URL of the page the link goes to */
  href: PropTypes.string,
  /** Will render specified icon */
  icon: PropTypes.node,
  /** Places icon before or after the link */
  iconPosition: PropTypes.oneOf(Object.values(LINK_ICON_POSITIONS)),
  /** Disallows user to interact with the component and adjusts appearance */
  isDisabled: PropTypes.bool,
  /** If true, inherits parent component font properties */
  isInline: PropTypes.bool,
  /** Specifies the label of the link */
  text: PropTypes.node.isRequired,
};

Link.defaultProps = {
  customTag: undefined,
  href: undefined,
  icon: undefined,
  iconPosition: LINK_ICON_POSITIONS.LEADING,
  isDisabled: false,
  isInline: false,
};

export { Link };
