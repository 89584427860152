import React from 'react';
import { transitionAnimation } from 'lib/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.color.primary['100'].value};
  display: flex;
  height: ${({ theme }) => theme.size.spacing.small.value};
  width: 100%;
`;

const StyledProgressBar = styled.div`
  background-color: ${({ theme }) => theme.color.primary['500'].value};
  width: ${({ progressPercent }) => `${progressPercent}%`};
  ${transitionAnimation('width')};
`;

export const StepProgressBar = ({ currentStep, totalSteps, ...other }) => {
  const progressPercent = isFinite(currentStep / totalSteps) ? (currentStep / totalSteps) * 100 : 0;
  return (
    <StyledContainer {...other}>
      <StyledProgressBar
        role="progressbar"
        aria-live="assertive"
        aria-label={`Step, ${currentStep} of ${totalSteps}`}
        progressPercent={progressPercent}
      />
    </StyledContainer>
  );
};

StepProgressBar.propTypes = {
  /** Total number of steps for the stepper */
  totalSteps: PropTypes.number,
  /** Current step number for the stepper */
  currentStep: PropTypes.number,
};

StepProgressBar.defaultProps = {
  totalSteps: 1,
  currentStep: 0,
};
