import { css } from 'styled-components';

import { getButtonContentElementsColor } from '../../shared-styles';
import { focusOutlinesStyles, getHexToRgb } from './../../../core';

export const secondaryButtonStateStyles = {
  default: {
    active: css`
      background-color: ${({ theme }) => theme.color.primary[200].value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.primary[700].value)}
    `,
    default: css`
      background-color: ${({ theme }) => theme.color.primary[50].value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.primary[600].value)}
    `,
    disabled: css`
      background-color: ${({ theme }) => theme.color.state.primary.disabled.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.text.light.disabled.value)};
    `,
    focus: css`
      background-color: ${({ theme }) => theme.color.primary[100].value};
      ${focusOutlinesStyles.primary};
    `,
    hover: css`
      background-color: ${({ theme }) => theme.color.primary[100].value};
    `,
  },
  defaultOnPrimaryBackground: {
    active: css`
      background-color: ${({ theme }) => theme.color.primary[900].value};
    `,
    default: css`
      background-color: ${({ theme }) => theme.color.primary[700].value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.additional.light.value)}
    `,
    disabled: css`
      ${({ theme }) => {
        const color = getHexToRgb(theme.color.additional.light.value);
        return css`
          background-color: ${`rgba(${color}, 0.15)`};
          ${getButtonContentElementsColor(`rgba(${color}, 0.4)`)};
        `;
      }}
    `,
    focus: css`
      background-color: ${({ theme }) => theme.color.primary[800].value};
      ${focusOutlinesStyles.light};
    `,
    hover: css`
      background-color: ${({ theme }) => theme.color.primary[800].value};
    `,
  },
};

export const getStateStyles = (isOnPrimaryBackground) => {
  return isOnPrimaryBackground
    ? secondaryButtonStateStyles.defaultOnPrimaryBackground
    : secondaryButtonStateStyles.default;
};
