import { classNamePrefix } from 'lib/utilities';
import { getRem } from './../core';

export const GLOBAL_NAVIGATION_CLASS = `${classNamePrefix}-global-navigation`;

export const CONTEXT_SWITCHER_VARIANTS = {
  APP_SWITCHER: 'appSwitcher',
  DEFAULT: 'default',
};

export const NAVIGATION_FOOTER_HEIGHTS = {
  COLLAPSED: getRem(96),
  EXPANDED: getRem(52),
};

export const NAVIGATION_HEADER_HEIGHT = getRem(56);

export const NAVIGATION_WIDTHS = {
  COLLAPSED: getRem(52),
  EXPANDED: getRem(256),
};

export const NAVIGATION_DROPDOWN_WIDTHS = {
  COLLAPSED: getRem(36),
  EXPANDED: getRem(240),
};
