import PropTypes from 'prop-types';

const nestedListItemPropTypes = {
  /** Avatar displayed near list item */
  avatar: PropTypes.node,
  /** Custom anchor Element */
  customAnchorElement: PropTypes.func,
  /** Sets hyperlink for anchor tag. Note that this will not work for parent items */
  href: PropTypes.string,
  /** Icon that is displayed near list item */
  icon: PropTypes.node,
  /** Unique identifier of the item */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /** Optional children items list */
  items: PropTypes.arrayOf(PropTypes.shape({})),
  /** Additional content displayed near list item */
  metadata: PropTypes.node,
  /** Callback which is called on item click */
  onClick: PropTypes.func,
  /** Custom anchor Element */
  renderCustomAnchorElement: PropTypes.func,
  /** Secondary text displayed near text */
  subtext: PropTypes.node,
  /** Text displayed for the item */
  text: PropTypes.node.isRequired,
};

export { nestedListItemPropTypes };
