import { filterString } from 'lib/utilities';

import { FORMATTING_CHARACTERS } from '../../regexes';
import { replaceHiddenWithValue } from './replaceHiddenWithValue';

export const getValueAfterTypingInSelectionRange = (value, selectionStart, selectionEnd, targetValue) => {
  const valueWithRemovedSelectionRange = `${value.slice(0, selectionStart)}${value.slice(selectionEnd)}`;

  const unformattedValue = filterString(
    valueWithRemovedSelectionRange,
    (valueCharacter) => !FORMATTING_CHARACTERS.includes(valueCharacter)
  );

  return replaceHiddenWithValue(unformattedValue, targetValue);
};
