import React from 'react';
import { removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ChipCheckboxIcon } from '../elements';
import { getRem } from './../../core';

import { ChipContent } from './ChipContent';

const StyledChipContent = styled(ChipContent)`
  padding-left: ${getRem('30px')};
`;

const ChipContentWithCheckbox = React.forwardRef(({ hasCheckbox, ...other }, ref) => {
  const renderIcon = () => !!hasCheckbox && <ChipCheckboxIcon />;

  const propsWithoutExcludedOptions = removeObjectProperties(other, 'renderBeforeContent');

  return <StyledChipContent ref={ref} renderBeforeContent={renderIcon} {...propsWithoutExcludedOptions} />;
});

ChipContentWithCheckbox.propTypes = {
  /** If true, chips won't be focusable via keyboard */
  disableFocus: PropTypes.bool,
  /** Shows checkbox inside the button. Used in filter chips */
  hasCheckbox: PropTypes.bool,
  /** If true, renders chip option as selected */
  isSelected: PropTypes.bool,
  /** Chip label */
  label: PropTypes.node.isRequired,
  /** Callback function which is triggered on chip click */
  onClick: PropTypes.func,
  /** Value of the chip */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ChipContentWithCheckbox.defaultProps = {
  disableFocus: false,
  hasCheckbox: undefined,
  isSelected: false,
  onClick: undefined,
  value: null,
};

export { ChipContentWithCheckbox };
