import React from 'react';
import { typographyCaption, getHexToRgb } from 'lib/core';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StepLabel = styled.span`
  ${({ theme }) => css`
    ${typographyCaption(theme)};
    color: rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.7);
  `}
`;

export const StepSecondaryLabel = ({ children, ...other }) => {
  return <StepLabel {...other}>{children}</StepLabel>;
};

StepSecondaryLabel.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node,
};

StepSecondaryLabel.defaultProps = {
  children: undefined,
};
