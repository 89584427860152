import { css } from 'styled-components';

import { getButtonContentElementsColor } from '../../shared-styles';
import { focusOutlinesStyles, getHexToRgb } from './../../../core';

const lightBackgroundStateColorStyles = {
  gray: {
    active: css`
      background-color: ${({ theme }) => theme.color.state.dark.pressed.value};
    `,
    default: css`
      ${({ theme }) => getButtonContentElementsColor(theme.color.text.light.secondary.value)}
    `,
    disabled: css`
      ${({ theme }) => getButtonContentElementsColor(theme.color.text.light.disabled.value)};
    `,
    focus: css`
      background-color: ${({ theme }) => theme.color.state.default.focus.value};
      ${focusOutlinesStyles.dark};
    `,
    hover: css`
      background-color: ${({ theme }) => theme.color.state.default.hover.value};
    `,
  },
  primary: {
    active: css`
      background-color: ${({ theme }) => theme.color.state.primary.pressed.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.primary[700].value)}
    `,
    default: css`
      ${({ theme }) => getButtonContentElementsColor(theme.color.primary[500].value)}
    `,
    disabled: css`
      ${({ theme }) => getButtonContentElementsColor(theme.color.text.light.disabled.value)};
    `,
    focus: css`
      background-color: ${({ theme }) => theme.color.state.primary.focus.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.primary[600].value)}
      ${focusOutlinesStyles.primary};
    `,
    hover: css`
      background-color: ${({ theme }) => theme.color.state.primary.hover.value};
      ${({ theme }) => getButtonContentElementsColor(theme.color.primary[600].value)};
    `,
  },
};

const primaryBackgroundStateColorStyles = {
  active: css`
    background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.3)`};
  `,
  default: css`
    ${({ theme }) => getButtonContentElementsColor(theme.color.additional.light.value)}
  `,
  disabled: css`
    ${({ theme }) => getButtonContentElementsColor(`rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.4)`)};
  `,
  focus: css`
    background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.2)`};
    ${focusOutlinesStyles.light};
  `,
  hover: css`
    background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.2)`};
  `,
};

// It was decided to use gray button styling only
// but allow to cover primary color styling in cases we do not know
const iconButtonStateStyles = {
  dark: {
    gray: primaryBackgroundStateColorStyles,
    primary: primaryBackgroundStateColorStyles,
  },
  light: lightBackgroundStateColorStyles,
  primary: {
    gray: primaryBackgroundStateColorStyles,
    primary: primaryBackgroundStateColorStyles,
  },
};

export const getStateStyles = (backgroundAppearence, iconColor) => {
  return iconButtonStateStyles[backgroundAppearence][iconColor];
};
