import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const getQuickFilterValue = (gridApi) => {
  return gridApi.filterManager.quickFilter;
};

const CustomNoRowsOverlay = ({ message, showEnteredValue, ...gridParams }) => {
  const {
    addRenderedRowListener,
    addRowCompListener,
    agGridReact,
    colDef,
    columnApi,
    eGridCell,
    eParentOfValue,
    formatValue,
    frameworkComponentWrapper,
    getValue,
    reactContainer,
    refreshCell,
    rowIndex,
    setValue,
    valueFormatted,
    ...otherProps
  } = gridParams;
  const textClass = 'ag-cell__text';
  const fullTextClass = classNames(textClass, `${textClass}--overlay-text`);

  const filterValue = (getQuickFilterValue(gridParams.api) || '').toLowerCase();

  return (
    <span className={fullTextClass} {...otherProps}>
      {message}
      {showEnteredValue && <strong>{` "${filterValue}"`}</strong>}
    </span>
  );
};

CustomNoRowsOverlay.propTypes = {
  /** Message for no rows found overlay */
  message: PropTypes.node.isRequired,
  /** If true, adds the search value to the end of the message */
  showEnteredValue: PropTypes.bool,
};

CustomNoRowsOverlay.defaultProps = {
  showEnteredValue: false,
};

export { CustomNoRowsOverlay };
