import React, { useState, useRef } from 'react';

import { KEY_CODES, removeObjectProperties, useIsKeyPressed } from 'lib/utilities';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import { ShowHideValueToggleButton } from '../blocks';
import { DEFAULT_MASK, HIDEABLE_FORMAT_CHARS } from '../constants';
import {
  getHiddenMask,
  getHiddenValue,
  getValueAfterBackspacePress,
  getValueAfterDeletePress,
  getValueAfterTypingInSelectionRange,
  replaceHiddenWithValue,
  useSelectionRange,
} from '../utils';
import { Input, INPUT_ICON_POSITIONS, INPUT_SIZES } from './../../../input';

// maxLength, minLength props breaks masking because length is limited by the InputMask component
const MaskedInput = React.forwardRef((props, ref) => (
  <InputMask ref={ref} {...removeObjectProperties(props, 'maxLength', 'minLength')} />
));

const HideableFormattedInput = React.forwardRef(
  (
    {
      customTag,
      dataTestId,
      defaultIsHidden,
      isDisabled,
      mask,
      onChange,
      showSuffixElement,
      toggleHideLabel,
      toggleShowLabel,
      value,
      ...other
    },
    ref
  ) => {
    const inputRef = useRef();
    const [isHidden, setIsHidden] = useState(defaultIsHidden);

    const isBackspacePressedRef = useIsKeyPressed(KEY_CODES.BACKSPACE);
    const isDeleteKeyPressedRef = useIsKeyPressed(KEY_CODES.DELETE);

    const [selectionStartRef, selectionEndRef, handleSelect] = useSelectionRange();

    const hiddenMask = getHiddenMask(mask);

    const valueShown = isHidden ? getHiddenValue(value, hiddenMask) : value;

    const getUpdatedTargetValueWhenHidden = (event) => {
      if (isBackspacePressedRef.current) {
        return getValueAfterBackspacePress(value, selectionStartRef.current, selectionEndRef.current, hiddenMask);
      }

      if (isDeleteKeyPressedRef.current) {
        return getValueAfterDeletePress(value, selectionStartRef.current, selectionEndRef.current, hiddenMask);
      }

      const selectionRangeSelected = selectionStartRef.current !== selectionEndRef.current;
      if (selectionRangeSelected) {
        return getValueAfterTypingInSelectionRange(
          value,
          selectionStartRef.current,
          selectionEndRef.current,
          event.target.value
        );
      }

      return replaceHiddenWithValue(value, event.target.value);
    };

    const handleChange = (event) => {
      if (isHidden) {
        const updatedValue = getUpdatedTargetValueWhenHidden(event);

        // eslint-disable-next-line no-param-reassign
        event.target.value = updatedValue;
      }

      onChange(event);
    };

    const toggleShowHide = (event) => (isDisabled ? event.preventDefault() : setIsHidden(!isHidden));

    const renderShowHideValueToggleButton = () => (
      <ShowHideValueToggleButton
        data-testid={dataTestId ? `${dataTestId}-show-hide-value-button` : undefined}
        isDisabled={isDisabled}
        isHidden={isHidden}
        onToggleShowHide={toggleShowHide}
        toggleHideLabel={toggleHideLabel}
        toggleShowLabel={toggleShowLabel}
      />
    );

    const reactInputMaskProps = {
      formatChars: HIDEABLE_FORMAT_CHARS,
      mask: isHidden ? getHiddenMask(mask) : mask,
      maskChar: '',
    };

    const Tag = customTag || Input;

    return (
      <Tag
        as={MaskedInput}
        dataTestId={dataTestId}
        inputRef={(el) => {
          inputRef.current = el;
        }}
        isDisabled={isDisabled}
        isObscured={isHidden && !!valueShown}
        onChange={handleChange}
        onSelect={handleSelect}
        ref={ref}
        renderSuffixElement={showSuffixElement ? renderShowHideValueToggleButton : undefined}
        value={valueShown}
        {...reactInputMaskProps}
        {...other}
        iconPosition={INPUT_ICON_POSITIONS.LEADING}
      />
    );
  }
);

HideableFormattedInput.propTypes = {
  /** Ability to supply a different input element instead of the default one */
  customTag: PropTypes.elementType,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, initially the input value will be hidden */
  defaultIsHidden: PropTypes.bool,
  /** If true, custom validation is being enabled instead of built in component validation */
  enableCustomValidation: PropTypes.bool,
  /** Message to be displayed when input is in error state */
  errorMessage: PropTypes.node,
  /** When true, input is in error state */
  hasError: PropTypes.bool,
  /** Text to be displayed as a helper text near the input field */
  helperText: PropTypes.node,
  /** Icon to be displayed in input field */
  icon: PropTypes.node,
  /** Identifier of the input component */
  id: PropTypes.string.isRequired,
  /* If true, input is disabled and its value cannot be edited */
  isDisabled: PropTypes.bool,
  /** If true, input is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, isRequired asterisk will be shown */
  isRequired: PropTypes.bool,
  /** Label of the input field */
  label: PropTypes.node.isRequired,
  /** Mask format of the formatted input */
  mask: PropTypes.string,
  /** Name of the input */
  name: PropTypes.string.isRequired,
  /** Callback to be called when input's value is being changed by user interaction */
  onChange: PropTypes.func,
  /** Text to be displayed when input is empty */
  placeholder: PropTypes.node,
  /** String to be displayed before the input value. Prefix text should be 1 character. */
  prefixText: PropTypes.node,
  /** Visibility Icon button to be displayed in input field, which can toggle visibility of input value on click */
  showSuffixElement: PropTypes.bool,
  /** Set the size of the input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  /** String to be displayed after the input value. Text should contain up to 5 characters, to not get cutted */
  suffixText: PropTypes.node,
  /** Text that is read for screen readers, when value inside the input is hidden */
  toggleHideLabel: PropTypes.node,
  /** Text that is read for screen readers, when value inside the input is visible */
  toggleShowLabel: PropTypes.node,
  /** Current value of the input field */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

HideableFormattedInput.defaultProps = {
  customTag: undefined,
  dataTestId: undefined,
  defaultIsHidden: true,
  enableCustomValidation: false,
  errorMessage: '',
  hasError: false,
  helperText: '',
  icon: undefined,
  isDisabled: false,
  isReadOnly: false,
  isRequired: false,
  mask: DEFAULT_MASK,
  onChange: () => {},
  placeholder: '',
  prefixText: '',
  showSuffixElement: true,
  size: INPUT_SIZES.STANDARD,
  suffixText: '',
  toggleHideLabel: 'Show value',
  toggleShowLabel: 'Hide value',
};

export { HideableFormattedInput };
