import React from 'react';

import { Avatar, AVATAR_COLORS, AVATAR_SIZES, AVATAR_VARIANTS } from 'lib/avatar';
import { removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { iconElementBasePositioning } from '../iconElementBasePositioning';
import { getRem } from './../../core';
import { ChipContent } from './ChipContent';

const StyledAvatarWrapper = styled(Avatar)`
  ${iconElementBasePositioning};
  left: 0;

  && {
    position: absolute;
  }
`;

const StyledChipContent = styled(ChipContent)`
  padding-left: ${getRem('38px')};
`;

const ChipContentWithAvatar = React.forwardRef(({ avatarProps, ...other }, ref) => {
  const propsWithoutSize = removeObjectProperties(avatarProps, 'size');
  const renderAvatar = () => !!avatarProps && <StyledAvatarWrapper size={AVATAR_SIZES.SMALL} {...propsWithoutSize} />;

  const propsWithoutExcludedOptions = removeObjectProperties(other, 'renderBeforeContent');

  return <StyledChipContent renderBeforeContent={renderAvatar} ref={ref} {...propsWithoutExcludedOptions} />;
});

ChipContentWithAvatar.propTypes = {
  /** Object of properties, which are applied to avatar component. Without size */
  avatarProps: PropTypes.shape({
    /** Accessibility measurement for verbal image description */
    alt: PropTypes.node,
    /** Sets background color of avatar component */
    color: PropTypes.oneOf(Object.values(AVATAR_COLORS)),
    /** Outputs icon inside the avatar. Use icon component from the library */
    icon: PropTypes.node,
    /** Username, that this avatar depicts. */
    label: PropTypes.node.isRequired,
    /** Path to image file */
    src: PropTypes.string,
    /** Defines multiple sizes of the same image, allowing the browser to select the appropriate image source */
    srcset: PropTypes.string,
    /** Avatar variant. Values: [EMPTY, TEXT, ICON, IMAGE]. Default TEXT */
    variant: PropTypes.oneOf(Object.values(AVATAR_VARIANTS)),
  }),
  /** If true, chips won't be focusable via keyboard */
  disableFocus: PropTypes.bool,
  /** Chip label */
  label: PropTypes.node.isRequired,
  /** Callback function which is triggered on chip click */
  onClick: PropTypes.func,
  /** Value of the chip */
  value: PropTypes.node,
};

ChipContentWithAvatar.defaultProps = {
  avatarProps: undefined,
  disableFocus: false,
  onClick: undefined,
  value: null,
};

export { ChipContentWithAvatar };
