import React from 'react';

import { Button, BUTTON_BACKGROUND_APPEARANCES, BUTTON_VARIANTS } from 'lib/button';
import { MENU_HORIZONTAL_POSITIONS, MENU_VERTICAL_POSITIONS } from 'lib/context-menu';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SCREEN_SIZES } from '../constants';
import { getRem } from './../../core';

import { PageHeaderButtonOverflowMenu } from './PageHeaderButtonOverflowMenu';

const StyledPageHeaderTitleButtons = styled.div`
  display: flex;
  justify-content: center;
  min-width: 0;
  padding: ${getRem('12px')} ${({ theme }) => theme.size.spacing.large.value};
  padding-left: 0;
`;

const StyledOverflowMenuWrapper = styled.div`
  padding-right: ${({ theme }) => theme.size.spacing.medium.value};
`;

const StyledOverflowMenu = styled(PageHeaderButtonOverflowMenu)`
  margin-left: ${getRem('26px')};
`;

const StyledSecondaryButton = styled(Button)`
  margin-right: ${({ theme }) => theme.size.spacing.medium.value};
`;

const PageHeaderTitleButtons = ({ overflowMenuButtonProps, primaryButtonProps, secondaryButtonProps, ...other }) => (
  <StyledPageHeaderTitleButtons {...other}>
    {overflowMenuButtonProps && (
      <StyledOverflowMenuWrapper>
        <StyledOverflowMenu
          positionHorizontal={MENU_HORIZONTAL_POSITIONS.CENTER}
          positionVertical={MENU_VERTICAL_POSITIONS.TOP}
          {...overflowMenuButtonProps}
        />
      </StyledOverflowMenuWrapper>
    )}
    {secondaryButtonProps && (
      <StyledSecondaryButton
        backgroundAppearance={BUTTON_BACKGROUND_APPEARANCES.PRIMARY}
        {...secondaryButtonProps}
        variant={BUTTON_VARIANTS.SECONDARY}
      />
    )}
    {primaryButtonProps && (
      <Button
        backgroundAppearance={BUTTON_BACKGROUND_APPEARANCES.PRIMARY}
        {...primaryButtonProps}
        variant={BUTTON_VARIANTS.PRIMARY}
      />
    )}
  </StyledPageHeaderTitleButtons>
);

PageHeaderTitleButtons.propTypes = {
  /** Overflow menu button properties  */
  overflowMenuButtonProps: PropTypes.shape({
    /** Text for screen reader when context menu is being closed  */
    closeMenuText: PropTypes.node,
    /** Id value used for testing */
    dataTestId: PropTypes.string,
    /** Specifies default options */
    defaultOptions: PropTypes.arrayOf(
      PropTypes.shape({
        /** Specifies the URL of the option */
        href: PropTypes.string,
        /** Will render specified icon */
        icon: PropTypes.node,
        /** Specifies option id */
        id: PropTypes.string,
        /** If sets option state to disabled */
        isDisabled: PropTypes.bool,
        /** If sets option state to selected */
        isSelected: PropTypes.bool,
        /** Specifies the option label */
        label: PropTypes.node.isRequired,
        /** Callback to be called when option is clicked */
        onClick: PropTypes.func,
      })
    ),
    /** Specifies option properties */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        /** Unique identifier for the button */
        id: PropTypes.string,
        /** Callback that is called on click */
        onClick: PropTypes.func,
        /** Will display text inside button */
        text: PropTypes.node.isRequired,
      })
    ).isRequired,
    /** Screen size for which the component has to render */
    screenSize: PropTypes.oneOf(Object.values(SCREEN_SIZES)),
    /** Text for screen reader when context menu is being opened  */
    showMenuText: PropTypes.node,
  }),
  /** Primary Button */
  primaryButtonProps: PropTypes.shape({
    /** Callback to be called when Primary Button is clicked */
    onClick: PropTypes.func.isRequired,
    /** Primary Button text */
    text: PropTypes.node.isRequired,
  }),
  /** Secondary Button */
  secondaryButtonProps: PropTypes.shape({
    /** Callback to be called when Secondary Button is clicked */
    onClick: PropTypes.func.isRequired,
    /** Secondary Button text */
    text: PropTypes.node.isRequired,
  }),
};

PageHeaderTitleButtons.defaultProps = {
  overflowMenuButtonProps: undefined,
  primaryButtonProps: undefined,
  secondaryButtonProps: undefined,
};

export { PageHeaderTitleButtons };
