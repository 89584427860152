import styled from 'styled-components';

import { getRem } from './../../core';

import { DatePickerContainerBase } from './DatePickerContainerBase';

export const DatePickerContainer = styled(DatePickerContainerBase)`
  display: flex;
  max-width: ${getRem(224)};
  position: relative;
`;
