import React from 'react';
import { typographyBody1, getHexToRgb } from 'lib/core';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StepLabel = styled.span`
  ${({ isActive, theme }) => css`
    ${typographyBody1(theme)};
    color: ${isActive
      ? theme.color.additional.dark.value
      : `rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.7)`};
  `}
`;

export const StepPrimaryLabel = ({ children, isActive, isComplete, ...other }) => {
  return (
    <StepLabel isActive={isActive || isComplete} {...other}>
      {children}
    </StepLabel>
  );
};

StepPrimaryLabel.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node,
  /** Sets the step as active and make step clickable */
  isActive: PropTypes.bool,
  /** Mark the step as completed and make step clickable */
  isComplete: PropTypes.bool,
};

StepPrimaryLabel.defaultProps = {
  children: undefined,
  isActive: false,
  isComplete: false,
};
