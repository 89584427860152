import { filterString } from 'lib/utilities';

import { FORMATTING_CHARACTERS } from '../../regexes';
import { formatValue } from './formatValue';

export const getValueAfterDeletePress = (value, selectionStartParam, selectionEndParam, mask) => {
  // There are cases when selection start or end positions are beyond value length.
  // For those cases we set start and end positions to value.length
  const selectionStart = Math.min(selectionStartParam, value.length);
  const selectionEnd = Math.min(selectionEndParam, value.length);

  const isRangeSelected = selectionStart !== selectionEnd;

  const rangeRemoved = {
    end: isRangeSelected ? selectionEnd : selectionEnd + 1,
    start: selectionStart,
  };

  const valueWithRemovedSelectionRange = `${value.slice(0, rangeRemoved.start)}${value.slice(rangeRemoved.end)}`;

  const unformattedValue = filterString(
    valueWithRemovedSelectionRange,
    (valueCharacter) => !FORMATTING_CHARACTERS.includes(valueCharacter)
  );

  return formatValue(unformattedValue, mask);
};
