import React from 'react';
import { Tabs, TAB_ALIGNMENTS } from 'lib/tabs';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTabs = styled(Tabs)`
  background-color: ${({ theme }) => theme.color.primary['500'].value};
  overflow: hidden;
`;

const PageHeaderNavigationBlock = (props) => <StyledTabs alignment={TAB_ALIGNMENTS.LEFT} {...props} />;

PageHeaderNavigationBlock.propTypes = {
  /** Shows item at index as active */
  activeItemIndex: PropTypes.number.isRequired,
  /** Tabs data which gets rendered */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** Tab identifier */
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      /** Panel identifier */
      panelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      /** Function that renders panel component */
      renderPanel: PropTypes.func.isRequired,
      /** Function that renders tab component */
      renderTab: PropTypes.func.isRequired,
    })
  ).isRequired,
  /** Callback that is called on active item change */
  onActiveItemChange: PropTypes.func.isRequired,
  /** Provides a label that describes the purpose of the set of tabs */
  tabsLabel: PropTypes.node.isRequired,
};

export { PageHeaderNavigationBlock };
