import { FORMATTING_CHARACTERS } from '../../regexes';

export const formatValue = (value, mask) => {
  let result = '';
  let indexOfMask = 0;

  for (let i = 0; i < value.length; ) {
    if (FORMATTING_CHARACTERS.includes(mask[indexOfMask])) {
      result += mask[indexOfMask];
    } else {
      result += value[i];
      i += 1;
    }
    indexOfMask += 1;
  }

  return result;
};
