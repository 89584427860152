import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRem, typographyCaption } from '../../core';

const BADGE_SIZE = getRem(16);

const StyledBadge = styled.div`
  box-sizing: border-box;
  display: inline-block;
  line-height: 0;
`;

const StyledBadgeText = styled.span`
  ${({ theme }) => typographyCaption(theme)};
  align-items: center;
  background-color: ${({ badgeColor, theme }) => badgeColor || theme.color.system.negative[500].value};
  border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  box-sizing: border-box;
  color: ${({ textColor, theme }) => textColor || theme.color.additional.light.value};
  display: inline-flex;
  justify-content: center;
  line-height: normal;
  min-height: ${BADGE_SIZE};
  min-width: ${BADGE_SIZE};
  padding: 0 ${({ theme }) => theme.size.spacing.small.value};
  white-space: nowrap;
`;

const Badge = ({ badgeColor, children, dataTestId, textColor, ...other }) => (
  <StyledBadge data-testid={dataTestId} {...other}>
    <StyledBadgeText
      badgeColor={badgeColor}
      data-testid={dataTestId ? `${dataTestId}-text` : undefined}
      textColor={textColor}
    >
      {children}
    </StyledBadgeText>
  </StyledBadge>
);

Badge.propTypes = {
  /** Badge label for screen reader */
  'aria-label': PropTypes.node,
  /** Sets background color of Badge component */
  badgeColor: PropTypes.string,
  /** Any content inserted between the tags */
  children: PropTypes.node,
  /** Unique identifier of the component, required if used in 'withBadge' component. */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Sets the color of the text in badge */
  textColor: PropTypes.string,
  /** ID attribute to test the node */
  dataTestId: PropTypes.string,
};

Badge.defaultProps = {
  'aria-label': '',
  badgeColor: undefined,
  children: null,
  id: undefined,
  textColor: undefined,
  dataTestId: '',
};

export { Badge };
