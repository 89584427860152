import styled, { css } from 'styled-components';

import { getRem } from './../../core';

export const TableFooter = styled.div`
  background-color: ${({ theme }) => theme.color.additional.light.value};
  ${({ hideBorder }) =>
    !hideBorder &&
    css`
      border-color: ${({ theme }) => theme.color.gray[200].value};
      border-radius: 0 0 ${({ theme }) => theme.size.borderRadius.large.value}
        ${({ theme }) => theme.size.borderRadius.large.value};
      border-style: solid;
      border-width: 0 1px 1px;
    `}
  height: ${getRem(8)};

  @media print {
    border: none;
  }
`;
