import React, { cloneElement, useEffect, useRef } from 'react';

import { LINK_ICON_POSITIONS } from 'lib/link';
import { KEY_CODES } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PageHeaderLink } from '../elements';
import { getRem, transitionAnimation } from './../../core';

const StyledPageHeaderLinkBackground = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(270deg, rgba(0, 108, 250, 0) 0%, ${theme.color.primary[500].value} 30%)`};
  bottom: 0;
  padding-right: ${getRem(62)};
  position: absolute;
  top: 0;
  width: 100%;
`;

const StyledPageHeaderLinkContent = styled.div`
  ${transitionAnimation('opacity, visibility')}
  align-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  padding-top: ${({ theme }) => theme.size.spacing.medium.value};
  position: absolute;
  top: 0;
  visibility: hidden;
  white-space: nowrap;
  width: auto;

  &:hover {
    opacity: 1;
    visibility: visible;
  }
`;

const StyledPageHeaderIconWrapper = styled(({ children, ...other }) => cloneElement(children, other))`
  align-self: center;
  fill: currentColor;

  &:hover {
    ~ ${StyledPageHeaderLinkContent} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const StyledPageHeaderIconLinkWrapper = styled.div`
  align-self: flex-start;
  color: ${({ theme }) => theme.color.additional.light.value};
  display: flex;
  outline: none;
  padding-top: ${({ theme }) => theme.size.spacing.large.value};
  position: relative;

  &:focus {
    ${PageHeaderLink} {
      outline: auto;
    }

    ${StyledPageHeaderLinkContent} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const useKeyDownEventListener = () => {
  const linkRef = useRef();
  const wrapperRef = useRef();

  const handleKeydown = (event) => {
    const keyCode = event.keyCode || event.which || 0;

    if (keyCode === KEY_CODES.ENTER && linkRef && linkRef.current) {
      linkRef.current.click();
    }
  };

  useEffect(() => {
    const wrapperHandler = wrapperRef && wrapperRef.current;

    if (wrapperHandler) {
      wrapperHandler.addEventListener('keydown', handleKeydown);
    }

    return () => {
      if (wrapperHandler) {
        wrapperHandler.removeEventListener('keydown', handleKeydown);
      }
    };
  });

  return { linkRef, wrapperRef };
};

const PageHeaderIconLink = ({ href, icon, linkProps, text, ...other }) => {
  const { linkRef, wrapperRef } = useKeyDownEventListener();

  return (
    <StyledPageHeaderIconLinkWrapper ref={wrapperRef} tabIndex={0} {...other}>
      <StyledPageHeaderIconWrapper>{icon}</StyledPageHeaderIconWrapper>
      <StyledPageHeaderLinkContent>
        <StyledPageHeaderLinkBackground />
        <PageHeaderLink
          href={href}
          icon={icon}
          iconPosition={LINK_ICON_POSITIONS.LEADING}
          ref={linkRef}
          text={text}
          {...linkProps}
        />
      </StyledPageHeaderLinkContent>
    </StyledPageHeaderIconLinkWrapper>
  );
};

PageHeaderIconLink.propTypes = {
  /** Specifies the URL of the page the link goes to */
  href: PropTypes.string.isRequired,
  /** Renders specified icon */
  icon: PropTypes.node.isRequired,
  /** Additional properties applied to the Link element */
  linkProps: PropTypes.shape({ ...PageHeaderLink.propTypes }),
  /** Specifies the label of the link */
  text: PropTypes.node.isRequired,
};

PageHeaderIconLink.defaultProps = {
  linkProps: {},
};

export { PageHeaderIconLink };
