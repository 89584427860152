import { findItemById } from './findItemById';
import { getAllItemChildrenIds } from './getAllItemChildrenIds';

export const collapseChildrenItems = (expandedItemIds, items, itemId) => {
  const item = findItemById(items, itemId);

  const itemChildrenIds = getAllItemChildrenIds(item);

  const nextExpandedItemIds = { ...expandedItemIds };

  itemChildrenIds.forEach((id) => {
    nextExpandedItemIds[id] = undefined;
  });

  return nextExpandedItemIds;
};
