export const addCellEventHandlers = (eventHandlers) => {
  eventHandlers.forEach((handler) => {
    /** No other way to add event listener to ag-grid cell */
    document.querySelectorAll(`[col-id=${handler.columnId}][role=gridcell]`).forEach((el, index) => {
      el.addEventListener(handler.eventListener, (event) => {
        handler.handleEvent(event, index);
      });
    });
  });
};
