import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRem, truncateText, typographySubtitle2 } from './../../core';

const StyledCaptionContainer = styled.figcaption`
  align-items: flex-end;
  background: ${({ theme }) =>
    `linear-gradient(0deg, ${theme.color.text.light.secondary.value} 0%, rgba(0, 0, 0, 0) 100%)`};
  border-radius: inherit;
  display: flex;
  height: ${getRem('56px')};
  width: 100%;
`;

const StyledCaptionTitle = styled.span`
  ${({ theme }) => typographySubtitle2(theme)};
  color: ${({ theme }) => theme.color.text.dark.primary.value};
  margin: ${({ theme }) => `0 ${theme.size.spacing.large.value} ${theme.size.spacing.medium.value}`};
  ${truncateText};
`;

const CardMediaCaption = ({ children, ...other }) => (
  <StyledCaptionContainer {...other}>
    <StyledCaptionTitle>{children}</StyledCaptionTitle>
  </StyledCaptionContainer>
);

CardMediaCaption.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node,
};

CardMediaCaption.defaultProps = {
  children: undefined,
};

export { CardMediaCaption };
