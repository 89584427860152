import styled, { css } from 'styled-components';

import { ListItem, ListItemCheckbox } from '../../List/elements';
import { getRem } from './../../../core';

const getNestedListItemCheckboxStateStyles = (pseudoState, backgroundColor) => css`
  ${pseudoState} {
    ${ListItemCheckbox} {
      &::after {
        background-color: ${backgroundColor};
      }
    }
  }
`;

const getNestedListItemCheckboxStatesStyles = (isChecked, theme) => {
  const { default: d, primary: p } = theme.color.state;

  return css`
    ${getNestedListItemCheckboxStateStyles('&:hover', isChecked ? p.hover.value : d.hover.value)};
    ${getNestedListItemCheckboxStateStyles('&:focus', isChecked ? p.focus.value : d.focus.value)};
    ${getNestedListItemCheckboxStateStyles('&:active', isChecked ? p.pressed.value : d.pressed.value)};
  `;
};

const getNestedListItemContentPaddingLeft = (hasAvatar, hasIcon, isCheckable, isNested, nestingLevel) => {
  if (nestingLevel === 0) {
    if (!isCheckable && !isNested && !hasAvatar && !hasIcon) {
      return '44px';
    }
    if (!isNested) {
      return '32px';
    }
    return '8px';
  }

  if (nestingLevel === 1) {
    if (!isCheckable && !isNested && !hasAvatar && !hasIcon) {
      return '68px';
    }
    if (!isNested && !hasAvatar) {
      return '88px';
    }
    if (!isNested) {
      return '96px';
    }
    if (hasAvatar) {
      return '72px';
    }
    if (isCheckable || hasIcon) {
      return '64px';
    }
    return '38px';
  }

  if (nestingLevel === 2) {
    if (!isCheckable && !hasAvatar && !hasIcon) {
      return '84px';
    }
    if (isCheckable || hasIcon) {
      return '120px';
    }
    if (hasAvatar) {
      return '144px';
    }
  }

  return 0;
};

const NestedListItemContent = styled(ListItem).withConfig({
  shouldForwardProp: (prop) => !['hasIcon', 'isCheckable'].includes(prop),
})`
  padding-left: ${({ hasAvatar, hasIcon, isCheckable, isNested, nestingLevel }) =>
    getRem(getNestedListItemContentPaddingLeft(hasAvatar, hasIcon, isCheckable, isNested, nestingLevel))};

  ${({ isChecked, isDisabled, theme }) =>
    isChecked &&
    !isDisabled &&
    css`
      &:hover,
      &:focus {
        background-color: ${theme.color.state.primary.selected.value};
      }
    `}

  ${({ isChecked, theme }) => getNestedListItemCheckboxStatesStyles(isChecked, theme)};
`;

export { NestedListItemContent };
