export const MAX_ALLOWED_BUTTON_GROUP_RIGHT = 2;
export const OVERFLOW_MENU_MIN_ALLOWED_BUTTON = 2;

export const SCREEN_SIZES = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

export const MAX_ALLOWED_BUTTON_SCREEN_SIZES = {
  [SCREEN_SIZES.LARGE]: 25,
  [SCREEN_SIZES.MEDIUM]: 0,
  [SCREEN_SIZES.SMALL]: 0,
};

export const BUTTON_GROUP_ALIGNMENTS = {
  LEFT: 'left',
  RIGHT: 'right',
};
