import { filterString } from 'lib/utilities';

import { FORMATTING_CHARACTERS } from '../../regexes';
import { formatValue } from './formatValue';

// Gets the position of the nearest non formatting character to the left
// which will be removed
const getFirstNonFormattingCharacterPosition = (mask, selectionStart) => {
  let nonFormattingCharacterPosition = selectionStart - 1;

  while (FORMATTING_CHARACTERS.includes(mask[nonFormattingCharacterPosition])) {
    nonFormattingCharacterPosition -= 1;
  }

  return {
    end: nonFormattingCharacterPosition + 1,
    start: nonFormattingCharacterPosition,
  };
};

export const getValueAfterBackspacePress = (value, selectionStartParam, selectionEndParam, mask) => {
  // There are cases when selection start or end positions are beyond value length.
  // For those cases we set start and end positions to value.length
  const selectionStart = Math.min(selectionStartParam, value.length);
  const selectionEnd = Math.min(selectionEndParam, value.length);

  const isRangeSelected = selectionStart !== selectionEnd;

  const rangeRemoved = isRangeSelected
    ? {
        end: selectionEnd,
        start: selectionStart,
      }
    : getFirstNonFormattingCharacterPosition(mask, selectionStart);

  const valueWithRemovedSelectionRange = `${value.slice(0, rangeRemoved.start)}${value.slice(rangeRemoved.end)}`;

  const unformattedValue = filterString(
    valueWithRemovedSelectionRange,
    (valueCharacter) => !FORMATTING_CHARACTERS.includes(valueCharacter)
  );

  return formatValue(unformattedValue, mask);
};
