import styled, { css } from 'styled-components';

import { getLineHeight, getRem } from './../../core';

const styledheaderTitle = (token) => css`
  font-family: ${token.fontFamily.value};
  font-size: ${token.fontSize.value};
  font-weight: ${token.fontWeight.value};
  letter-spacing: ${token.letterSpacing.value};
  line-height: ${getLineHeight(token.lineHeight.value, token.fontSize.value)};
  margin: 0;
`;

const PageHeaderTitle = styled.h1`
  ${({ theme }) => styledheaderTitle(theme.typography.pageHeaderTitle)};
  color: ${({ theme }) => theme.color.additional.light.value};
  padding: ${getRem('12px')} ${({ theme }) => theme.size.spacing.large.value};
`;

export { PageHeaderTitle };
