import { css } from 'styled-components';

import { focusOutlinesStyles, getHexToRgb } from './../core';

const disabledStyles = css`
  color: ${({ theme }) => theme.color.text.light.disabled.value};
  fill: ${({ theme }) => theme.color.text.light.disabled.value};
`;

export const switchStateStylesUnchecked = (isDisabled, StyledIndicator) => ({
  active: isDisabled
    ? []
    : css`
        ${StyledIndicator} {
          &::after {
            background-color: ${({ theme }) => theme.color.state.dark.pressed.value};
          }
        }
      `,
  default: css``,
  disabled: disabledStyles,
  focus: isDisabled
    ? []
    : css`
        ${StyledIndicator} {
          &::after {
            background-color: ${({ theme }) => theme.color.state.default.focus.value};
            ${focusOutlinesStyles.dark};
          }
        }
      `,
  hover: isDisabled
    ? []
    : css`
        ${StyledIndicator} {
          &::after {
            background-color: ${({ theme }) => theme.color.state.default.hover.value};
          }
        }
      `,
});

export const switchStateStylesChecked = (isDisabled, StyledIndicator) => ({
  active: isDisabled
    ? []
    : css`
        ${({ theme }) => css`
          ${StyledIndicator} {
            &::after {
              background-color: rgba(${getHexToRgb(theme.color.primary[500].value)}, 0.25);
            }
          }
        `}
      `,
  default: css``,
  disabled: disabledStyles,
  focus: isDisabled
    ? []
    : css`
        ${StyledIndicator} {
          &::after {
            background-color: ${({ theme }) => theme.color.state.primary.focus.value};
            ${focusOutlinesStyles.primary};
          }
        }
      `,
  hover: isDisabled
    ? []
    : css`
        ${StyledIndicator} {
          &::after {
            background-color: ${({ theme }) => theme.color.state.primary.hover.value};
          }
        }
      `,
});

export const getStateStyles = (isChecked, isDisabled, StyledIndicator) =>
  isChecked
    ? switchStateStylesChecked(isDisabled, StyledIndicator)
    : switchStateStylesUnchecked(isDisabled, StyledIndicator);
