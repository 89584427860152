export const ICON_TOGGLE_ICON_TYPE = {
  BOOKMARK: 'bookmark',
  FAVORITE: 'favorite',
  STAR: 'star',
};

export const ICON_TOGGLE_SIZES = {
  LARGE: 'large',
  SMALL: 'small',
  STANDARD: 'standard',
};
