export const SHORTCUT_CHIPS_POSITIONS = {
  BOTTOM: 'bottom',
  NONE: 'none',
  RIGHT: 'after',
};

export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

export const DEFAULT_MINIMUM_YEAR = 1900;
export const DEFAULT_MAXIMUM_YEAR = 2100;

export const CUSTOM_ARIA_LABELS = {
  NEXT_MONTH: 'Click this button to move to the next month',
  PREVIOUS_MONTH: 'Click this button to move to the previous month',
};
