export const getOptionsWithSelectedValues = (options, selectedValues) =>
  options.map((option) =>
    option.options
      ? {
          ...option,
          options: option.options.map((subOption) => ({
            ...subOption,
            isSelected: selectedValues.includes(subOption.value),
          })),
        }
      : {
          ...option,
          isSelected: selectedValues.includes(option.value),
        }
  );
