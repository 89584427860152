import React from 'react';
import { IconChevronRight } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { removeOutline, transitionAnimation } from './../../core';

const StyledPanelChevron = styled(IconChevronRight).withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})`
  ${transitionAnimation('transform')}
  ${removeOutline}
    align-self: start;
  display: inline-flex;
  fill: ${({ theme }) => theme.color.gray[700].value};
  flex-shrink: 0;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(90deg);
    `}
`;

const PanelChevron = (props) => <StyledPanelChevron {...props} />;

PanelChevron.propTypes = {
  /** If true, PanelChevron will rotate to indicate state "open" */
  isOpen: PropTypes.bool,
};

PanelChevron.defaultProps = {
  isOpen: false,
};

export { PanelChevron };
