export const INPUT_SIZES = {
  LARGE: 'large',
  SMALL: 'small',
  STANDARD: 'standard',
};

export const INPUT_ICON_POSITIONS = {
  LEADING: 'leading',
  TRAILING: 'trailing',
};

export const BORDER_POSITIONS = {
  LEFT: 'left',
  NONE: 'none',
  RIGHT: 'right',
};

export const DIVIDER_POSITIONS = {
  LEFT: 'left',
  NONE: 'none',
  RIGHT: 'right',
};

export const PREFIX_MAX_CHARACTERS_ALLOWED = 1;

export const SUFFIX_MAX_CHARACTERS_ALLOWED = 5;

const INPUT_ICON_SIZE_IN_PIXELS = 24;

export const INPUT_PADDING_WITH_SUFFIX_OR_PREFIX_IN_PIXELS = INPUT_ICON_SIZE_IN_PIXELS + 20;
export const INLINE_INPUT_PADDING_WITH_SUFFIX_OR_PREFIX_IN_PIXELS = 34;
