import {
  ALPHABET_LETTERS_REGEX,
  ALPHANUMERICS_REGEX,
  ANY_CHARACTER_REGEX,
  NUMBERS_FORMATTING_CHARACTERS_REGEX,
  NUMBERS_REGEX,
} from '../regexes';

export const MASKABLE_FORMAT_CHARS = {
  '!': ANY_CHARACTER_REGEX,
  '#': NUMBERS_FORMATTING_CHARACTERS_REGEX,
  '*': ALPHANUMERICS_REGEX,
  9: NUMBERS_REGEX,
  a: ALPHABET_LETTERS_REGEX,
};
