import React, { useState } from 'react';

import { AVATAR_COLORS, AVATAR_SIZES, AVATAR_VARIANTS } from 'lib/avatar';
import { Input } from 'lib/input';
import { KEY_CODES, useErrorValidation, useKeyDownEventListener } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ChipGroup, ChipOption } from '../../elements';
import { Chip } from '../Chip';
import { INPUT_CHIPS_ERRORS } from './errors';

const StyledChipOption = styled(ChipOption)`
  margin-bottom: 0;
  margin-top: ${({ theme }) => theme.size.spacing.medium.value};
  min-width: 0;
`;

const StyledChip = styled(Chip)`
  width: 100%;
`;

const InputChips = ({ avatarProps, dataTestId, icon, inputProps, onChange, onClick, options, ...other }) => {
  const [inputValue, setInputValue] = useState('');
  const isInputValueInList = inputValue ? options.includes(inputValue) : false;

  const errors = [isInputValueInList && INPUT_CHIPS_ERRORS.INPUT_VALUE_IN_LIST].filter(Boolean);

  const { onChildError: onInputError, updatedHasError } = useErrorValidation(errors, inputProps);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleEnterPressed = () => {
    if (inputValue && !isInputValueInList) {
      setInputValue('');
      onChange([...options, inputValue]);
    }
  };

  const handleDelete = (option) => {
    const updatedSelectedChips = options.filter((chip) => chip !== option);
    onChange(updatedSelectedChips);
  };

  useKeyDownEventListener(KEY_CODES.ENTER, handleEnterPressed);

  const getAvatarProps = (option) => (avatarProps ? { ...avatarProps, label: option } : undefined);

  return (
    <div data-testid={dataTestId} {...other}>
      <Input
        {...inputProps}
        enableCustomValidation
        dataTestId={dataTestId ? `${dataTestId}-input` : undefined}
        onChange={handleInputChange}
        value={inputValue}
        hasError={updatedHasError}
        onError={onInputError}
      />
      <ChipGroup>
        {options.map((option, index) => {
          return (
            <StyledChipOption key={option}>
              <StyledChip
                avatarProps={getAvatarProps(option)}
                dataTestId={dataTestId ? `${dataTestId}-button-${index}` : undefined}
                isSelected
                icon={icon}
                label={option}
                onClick={onClick}
                onRemove={() => handleDelete(option)}
                value={option}
                {...option}
              />
            </StyledChipOption>
          );
        })}
      </ChipGroup>
    </div>
  );
};

const { onChange, value, ...inputProps } = Input.propTypes;

InputChips.propTypes = {
  /** Object of properties, which is applied to avatar component */
  avatarProps: PropTypes.shape({
    /** Accessibility measurement for verbal image description */
    alt: PropTypes.node,
    /** Sets background color of avatar component */
    color: PropTypes.oneOf(Object.values(AVATAR_COLORS)),
    /** Outputs icon inside the avatar. Use icon component from the library */
    icon: PropTypes.node,
    /** Avatar wrapper size. Icon size changes depending on wrapper size */
    size: PropTypes.oneOf(Object.values(AVATAR_SIZES)),
    /** Path to image file */
    src: PropTypes.string,
    /** Defines multiple sizes of the same image, allowing the browser to select the appropriate image source */
    srcset: PropTypes.string,
    /** Avatar variant. Values: [EMPTY, TEXT, ICON, IMAGE]. Default TEXT */
    variant: PropTypes.oneOf(Object.values(AVATAR_VARIANTS)),
  }),
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Shows icon inside the button. Use icon component from the library */
  icon: PropTypes.node,
  /** Object of properties, which is applied to Input component */
  inputProps: PropTypes.shape({
    ...inputProps,
  }),
  /** Callback function which is triggered on Enter */
  onChange: PropTypes.func,
  /** Callback function which is triggered on chip click */
  onClick: PropTypes.func,
  /** Array of options */
  options: PropTypes.arrayOf(PropTypes.node).isRequired,
};

InputChips.defaultProps = {
  avatarProps: undefined,
  dataTestId: '',
  icon: undefined,
  inputProps: {},
  onChange: () => {},
  onClick: undefined,
};

export { InputChips };
