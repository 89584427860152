import styled from 'styled-components';

import { getRem, typographyButton, wordBreak } from './../../core';

export const PanelTitle = styled.span`
  ${({ theme }) => typographyButton(theme)}
  ${wordBreak}
    margin-right: ${getRem(24)};
  text-align: left;
`;
