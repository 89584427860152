import { flatMap } from 'lib/utilities';

const getItemIdAndAllItemChildrenIds = (item) => {
  const hasChildItems = item.items && item.items.length > 0;

  const childrenIds = hasChildItems ? flatMap(item.items, getItemIdAndAllItemChildrenIds) : [];

  return [item.id, ...childrenIds];
};

export const getAllItemChildrenIds = (item) => {
  const [, ...childrenIds] = getItemIdAndAllItemChildrenIds(item);

  return childrenIds;
};
