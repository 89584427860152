import React from 'react';

import { BUTTON_BACKGROUND_APPEARANCES, TextButton } from 'lib/button';
import { IconChevronRight } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { footerButtonStyles } from '../shared-styles';
import { getRem } from './../../core';

const StyledToggleButton = styled(TextButton)`
  ${footerButtonStyles};
  margin-left: auto;
  margin-top: auto;
  width: ${getRem(36)};
  ${({ isCollapsed }) =>
    !isCollapsed &&
    css`
      /* No other way to interact with icon */
      svg {
        transform: rotate(180deg);
      }
    `}

  && {
    &:hover,
    &:focus {
      box-shadow: none;

      &::after {
        background-color: ${({ theme }) => theme.color.state.light.hover.value};
      }
    }
  }
`;

const ToggleButton = ({ collapsedToggleLabel, expandedToggleLabel, isCollapsed, onCollapse, onExpand, ...other }) => {
  const buttonText = isCollapsed ? collapsedToggleLabel : expandedToggleLabel;
  const handleToggle = isCollapsed ? onExpand : onCollapse;

  return (
    <StyledToggleButton
      backgroundAppearance={BUTTON_BACKGROUND_APPEARANCES.DARK}
      hideText
      icon={<IconChevronRight />}
      isCollapsed={isCollapsed}
      onClick={handleToggle}
      text={buttonText}
      {...other}
    />
  );
};

ToggleButton.propTypes = {
  /** Text which is shown for screen readers when navigation is collapsed */
  collapsedToggleLabel: PropTypes.node,
  /** Text which is shown for screen readers when navigation is expanded */
  expandedToggleLabel: PropTypes.node,
  /** If true, navigation is collapsed */
  isCollapsed: PropTypes.bool,
  /** Callback function which is called when navigation is being collapsed */
  onCollapse: PropTypes.func,
  /** Callback function which is called when navigation is being expanded */
  onExpand: PropTypes.func,
};

ToggleButton.defaultProps = {
  collapsedToggleLabel: 'Expand navigation',
  expandedToggleLabel: 'Collapse navigation',
  isCollapsed: false,
  onCollapse: undefined,
  onExpand: undefined,
};

export { ToggleButton };
