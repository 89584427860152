import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CardMediaCaption, CardMediaImage } from '../elements';

const StyledCardMediaCaption = styled(CardMediaCaption)`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
`;

const CardMedia = ({ alt, dataTestId, hideTitle, isRounded, isSquare, src, title, ...other }) => (
  <CardMediaImage
    src={src}
    alt={alt}
    dataTestId={dataTestId ? `${dataTestId}-image` : undefined}
    isRounded={isRounded}
    isSquare={isSquare}
    {...other}
  >
    {!hideTitle && !!title && (
      <StyledCardMediaCaption data-testid={dataTestId ? `${dataTestId}-image-title` : undefined}>
        {title}
      </StyledCardMediaCaption>
    )}
  </CardMediaImage>
);

CardMedia.propTypes = {
  /** Accessibility measurement for verbal image description */
  alt: PropTypes.node,
  /** If true, title will be hidden */
  hideTitle: PropTypes.bool,
  /** If true, image will have bottom border radius */
  isRounded: PropTypes.bool,
  /** If true, image will be rendered as square */
  isSquare: PropTypes.bool,
  /** Specifies the URL of an image */
  src: PropTypes.string,
  /** Displays provided title with predefined style */
  title: PropTypes.node,
  /** ID for testing the node */
  dataTestId: PropTypes.string,
};

CardMedia.defaultProps = {
  alt: '',
  hideTitle: false,
  isRounded: false,
  isSquare: false,
  src: '',
  title: '',
  dataTestId: '',
};

export { CardMedia };
