import React, { useState } from 'react';

import { useOnError } from 'lib/utilities';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { CURRENCIES, CURRENCY_PREFIXES, PRECISION } from '../../constants';
import { CURRENCY_INPUT_ERRORS } from '../../errors';
import { Input, INPUT_ICON_POSITIONS, INPUT_SIZES } from './../../../input';

const CurrencyInput = React.forwardRef(
  (
    {
      allowNegative,
      currency,
      customTag,
      enableCustomValidation,
      errorMessage,
      hasError,
      helperText,
      id,
      isDisabled,
      isReadOnly,
      isRequired,
      label,
      maximumValue,
      minimumValue,
      name,
      onBlur,
      onChange,
      onError,
      onValueChange,
      value,
      ...other
    },
    ref
  ) => {
    const [isEditedSinceFocus, setIsEditedSinceFocus] = useState(false);
    const [hasNegativeZero, setHasNegativeZero] = useState(false);

    const numberValue = value ? Number(value.split(',').join('')) : 0;

    const isEmpty = value === '';
    const hasNegativeSymbol = value === '-';

    const minimumValueError =
      minimumValue !== undefined && numberValue < minimumValue && !isEmpty && isEditedSinceFocus;
    const maximumValueError = maximumValue !== undefined && numberValue > maximumValue && !isEmpty;
    const isRequiredAndEmptyError = isRequired && (isEmpty || hasNegativeSymbol) && isEditedSinceFocus;

    const errors = [
      isRequiredAndEmptyError && CURRENCY_INPUT_ERRORS.REQUIRED,
      maximumValueError && CURRENCY_INPUT_ERRORS.MAX_VALUE,
      minimumValueError && CURRENCY_INPUT_ERRORS.MIN_VALUE,
      hasNegativeZero && isEditedSinceFocus && CURRENCY_INPUT_ERRORS.HAS_NEGATIVE_ZERO,
    ].filter(Boolean);

    useOnError({
      errors,
      onError,
    });

    const updatedHasError = enableCustomValidation ? hasError : errors.length > 0;

    let numberFormatProps = {
      decimalScale: PRECISION[currency],
      fixedDecimalScale: true,
      thousandSeparator: true,
    };

    other = { ...other, prefixText: CURRENCY_PREFIXES[currency] };

    if (CURRENCIES.FR_CA === currency) {
      numberFormatProps = {
        decimalScale: PRECISION[currency],
        fixedDecimalScale: true,
        thousandSeparator: ' ',
        decimalSeparator: ',',
      };
      other = { ...other, prefixText: null, suffixText: CURRENCY_PREFIXES[currency], textalign: 'right' };
    }

    const removeEmptyNegativeSign = (event) => {
      if (event && event.target && event.target.value === '-') {
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
      }
    };

    const handleValueChange = (values) => {
      const isNegativeZero = Object.is(values.floatValue, -0);

      setHasNegativeZero(isNegativeZero);
      onValueChange(values);
    };

    const handleBlur = (event) => {
      onBlur(event);
      setIsEditedSinceFocus(true);
    };

    const handleChange = (event) => {
      removeEmptyNegativeSign(event);
      onChange(event);
      setIsEditedSinceFocus(false);
    };

    const handleFocus = () => {
      setIsEditedSinceFocus(false);
    };

    const Tag = customTag || Input;

    return (
      <Tag
        allowNegative={allowNegative}
        as={NumberFormat}
        enableCustomValidation
        enableIsRequiredValidation
        errorMessage={errorMessage}
        hasError={updatedHasError}
        helperText={helperText}
        id={id}
        isDisabled={isDisabled}
        isNumericString
        isReadOnly={isReadOnly}
        isRequired={isRequired}
        label={label}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onValueChange={handleValueChange}
        precision={PRECISION[currency]}
        ref={ref}
        value={value}
        {...numberFormatProps}
        {...other}
      />
    );
  }
);

CurrencyInput.propTypes = {
  /** If true, allow to input negative values */
  allowNegative: PropTypes.bool,
  /** Currency of the input */
  currency: PropTypes.oneOf(Object.values(CURRENCIES)),
  /** Ability to supply a different input element instead of the default one */
  customTag: PropTypes.elementType,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, custom validation is being enabled instead of built in component validation */
  enableCustomValidation: PropTypes.bool,
  /** String to be displayed when input is in error state */
  errorMessage: PropTypes.node,
  /** When true, input is in error state */
  hasError: PropTypes.bool,
  /** String to display as a helper text near the input field */
  helperText: PropTypes.node,
  /** Icon to be displayed in input field */
  icon: PropTypes.node,
  /** Sets the position of icon */
  iconPosition: PropTypes.oneOf(Object.values(INPUT_ICON_POSITIONS)),
  /** Identifier of the input component */
  id: PropTypes.string.isRequired,
  /** If true, input is disabled and value of it cannot be edited */
  isDisabled: PropTypes.bool,
  /** If true, input is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, input has to be filled or error state would be displayed */
  isRequired: PropTypes.bool,
  /** Label of the input field */
  label: PropTypes.node.isRequired,
  /** Maximum value of the input */
  maximumValue: PropTypes.number,
  /** Minimum value of the input */
  minimumValue: PropTypes.number,
  /** Name of the input */
  name: PropTypes.string.isRequired,
  /** Callback to be called when input loses focus */
  onBlur: PropTypes.func,
  /** Callback to be called when input value is being changed by user interaction */
  onChange: PropTypes.func,
  /** Callback to be called when input validation fails */
  onError: PropTypes.func,
  /** Callback to be called when input value changes inside react-number-format plugin */
  onValueChange: PropTypes.func,
  /** Text to be displayed when input is empty */
  placeholder: PropTypes.node,
  /** String to be displayed before the input value. Prefix text should be 1 character. */
  prefixText: PropTypes.node,
  /** Set the size of the input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  /** String to be displayed after the input value. Text should contain up to 5 characters, to not get cutted */
  suffixText: PropTypes.node,
  /** Value of the currency input */
  value: PropTypes.string,
};

CurrencyInput.defaultProps = {
  allowNegative: true,
  currency: CURRENCIES.USD,
  customTag: undefined,
  dataTestId: undefined,
  enableCustomValidation: false,
  errorMessage: '',
  hasError: false,
  helperText: '',
  icon: undefined,
  iconPosition: INPUT_ICON_POSITIONS.TRAILING,
  isDisabled: false,
  isReadOnly: false,
  isRequired: false,
  maximumValue: undefined,
  minimumValue: undefined,
  onBlur: () => {},
  onChange: () => {},
  onError: () => {},
  onValueChange: () => {},
  placeholder: '',
  prefixText: '',
  size: INPUT_SIZES.STANDARD,
  suffixText: '',
  value: '',
};

export { CurrencyInput };
