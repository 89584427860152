import { BUTTON_BACKGROUND_APPEARANCES, BUTTON_ICON_COLORS } from 'lib/button';
import { css } from 'styled-components';

import { focusOutlinesStyles, getHexToRgb } from './../../core';

const disabledStyles = css`
  ${({ theme }) => css`
    color: ${theme.color.text.light.disabled.value};
    fill: ${theme.color.text.light.disabled.value};
  `};
`;

const iconToggleStateStylesSecondary = (isDisabled, StyledIndicator) => ({
  active: isDisabled
    ? []
    : css`
        ${StyledIndicator} {
          &::after {
            background-color: ${({ theme }) => theme.color.state.dark.pressed.value};
          }
        }
      `,
  default: css``,
  disabled: disabledStyles,
  focus: isDisabled
    ? []
    : css`
        ${StyledIndicator} {
          &::after {
            background-color: ${({ theme }) => theme.color.state.default.focus.value};
            ${focusOutlinesStyles.dark};
          }
        }
      `,
  hover: isDisabled
    ? []
    : css`
        ${StyledIndicator} {
          &::after {
            background-color: ${({ theme }) => theme.color.state.default.hover.value};
          }
        }
      `,
});

const iconToggleStateStylesPrimary = (isDisabled, StyledIndicator, isLightBackground) => ({
  active: isDisabled
    ? []
    : css`
        ${({ theme }) => css`
          ${StyledIndicator} {
            &::after {
              background-color: ${isLightBackground
                ? `rgba(${getHexToRgb(theme.color.primary[500].value)}, 0.25)`
                : `rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.3)`};
            }
          }
        `}
      `,
  default: css``,
  disabled: disabledStyles,
  focus: isDisabled
    ? []
    : css`
        ${({ theme }) => css`
          ${StyledIndicator} {
            &::after {
              background-color: ${isLightBackground
                ? `${theme.color.state.primary.focus.value}`
                : `${theme.color.state.dark.focus.value}`};
              ${isLightBackground ? focusOutlinesStyles.primary : focusOutlinesStyles.light};
            }
          }
        `}
      `,
  hover: isDisabled
    ? []
    : css`
        ${StyledIndicator} {
          &::after {
            background-color: ${({ theme }) => theme.color.state.primary.hover.value};
          }
        }
      `,
});

export const getStateStyles = (iconColor, isDisabled, StyledIndicator, backgroundAppearance) => {
  if (iconColor === BUTTON_ICON_COLORS.PRIMARY) {
    const isLightBackground = backgroundAppearance === BUTTON_BACKGROUND_APPEARANCES.LIGHT;
    return iconToggleStateStylesPrimary(isDisabled, StyledIndicator, isLightBackground);
  }
  return iconToggleStateStylesSecondary(isDisabled, StyledIndicator);
};
