import React from 'react';
import styled from 'styled-components';

const StyledSnapshotContainer = styled.div`
  min-height: 600px;
  width: 100%;
`;

export const SnapshotContainer = (props) => <StyledSnapshotContainer data-testid="sb-snapshot-container" {...props} />;

const StyledInputWrapper = styled.div`
  max-width: 224px;
`;

export const InputWrapper = (props) => <StyledInputWrapper data-testid="sb-input-wrapper" {...props} />;
