import React from 'react';

import { removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';

import { HideableFormattedInput } from '../../HideableFormattedInput';
import { SOCIAL_SECURITY_NUMBER_MASK } from '../constants';
import { INPUT_SIZES } from './../../../input';

const SocialSecurityNumberInput = React.forwardRef((props, ref) => (
  <HideableFormattedInput
    ref={ref}
    showSuffixElement
    inputmode="numeric"
    {...removeObjectProperties(props, 'showSuffixElement')}
  />
));

SocialSecurityNumberInput.propTypes = {
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, custom validation is being enabled instead of built in component validation */
  enableCustomValidation: PropTypes.bool,
  /** Message to be displayed when input is in error state */
  errorMessage: PropTypes.node,
  /** When true, input is in error state */
  hasError: PropTypes.bool,
  /** Text to be displayed as a helper text near the input field */
  helperText: PropTypes.node,
  /** Icon to be displayed in input field */
  icon: PropTypes.node,
  /** Identifier of the input component */
  id: PropTypes.string.isRequired,
  /* If true, input is disabled and its value cannot be edited */
  isDisabled: PropTypes.bool,
  /** If true, input is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, isRequired asterisk will be shown */
  isRequired: PropTypes.bool,
  /** Label of the input field */
  label: PropTypes.node.isRequired,
  /** Specifies an optional custom social security number input mask */
  mask: PropTypes.string,
  /** Name of the input */
  name: PropTypes.string.isRequired,
  /** Callback to be called when input's value is being changed by user interaction */
  onChange: PropTypes.func,
  /** Text to be displayed when input is empty */
  placeholder: PropTypes.node,
  /** String to be displayed before the input value. Prefix text should be 1 character. */
  prefixText: PropTypes.node,
  /** Set the size of the input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  /** String to be displayed after the input value. Text should contain up to 5 characters, to not get cutted */
  suffixText: PropTypes.node,
  /** Text that is read for screen readers, when value inside the input is hidden */
  toggleHideLabel: PropTypes.node,
  /** Text that is read for screen readers, when value inside the input is visible */
  toggleShowLabel: PropTypes.node,
  /** Current value of the input field */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

SocialSecurityNumberInput.defaultProps = {
  dataTestId: undefined,
  enableCustomValidation: false,
  errorMessage: '',
  hasError: false,
  helperText: '',
  icon: undefined,
  isDisabled: false,
  isReadOnly: false,
  isRequired: false,
  mask: SOCIAL_SECURITY_NUMBER_MASK,
  onChange: () => {},
  placeholder: '',
  prefixText: '',
  size: INPUT_SIZES.STANDARD,
  suffixText: '',
  toggleHideLabel: 'Show value',
  toggleShowLabel: 'Hide value',
};
export { SocialSecurityNumberInput };
