export const SWITCH_LABEL_POSITIONS = {
  AFTER: 'after',
  BEFORE: 'before',
};

export const SWITCH_SIZES = {
  LARGE: 'large',
  SMALL: 'small',
  STANDARD: 'standard',
};
