import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const StepContent = ({ children, ...other }) => {
  return <StyledContent {...other}>{children} </StyledContent>;
};

StepContent.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node,
};

StepContent.defaultProps = {
  children: undefined,
};
