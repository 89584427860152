import styled from 'styled-components';

import { wordBreak } from '../../core';

const PageHeaderWrapper = styled.div`
  ${wordBreak}
  background-color: ${({ theme }) => theme.color.primary['500'].value};
  flex: 1;
`;

export { PageHeaderWrapper };
