export const FORMATTING_CHARACTERS = ['-', '(', ')', ' ', '+', '/'];

export const NUMBERS_REGEX = '[0-9]';
export const NUMBERS_AND_HIDDEN_REGEX = '[0-9●]';

export const NUMBERS_FORMATTING_CHARACTERS_REGEX = `[0-9${FORMATTING_CHARACTERS.join('')}]`;
export const NUMBERS_FORMATTING_CHARACTERS_AND_HIDDEN_REGEX = `[0-9${FORMATTING_CHARACTERS.join('')}●]`;

export const ALPHABET_LETTERS_REGEX = '[A-Za-z]';
export const ALPHABET_LETTERS_AND_HIDDEN_REGEX = '[A-Za-z●]';

export const ALPHANUMERICS_REGEX = '[A-Za-z0-9]';
export const ALPHANUMERICS_AND_HIDDEN_REGEX = '[A-Za-z0-9●]';

export const ANY_CHARACTER_REGEX = '[^]*';
