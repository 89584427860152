import React from 'react';

import { BUTTON_ICON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS } from 'lib/button';
import { ICON_SIZE } from 'lib/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PageHeaderButtonGroup, PageHeaderButtonOverflowMenu } from '../blocks';
import {
  BUTTON_GROUP_ALIGNMENTS,
  MAX_ALLOWED_BUTTON_GROUP_RIGHT,
  OVERFLOW_MENU_MIN_ALLOWED_BUTTON,
  SCREEN_SIZES,
} from '../constants';
import { getRem } from './../../core';

const StyledWrapper = styled.div`
  display: flex;
  padding: ${({ theme }) =>
    `0 ${theme.size.spacing.large.value} ${theme.size.spacing.small.value} ${theme.size.spacing.large.value}`};
`;

const StyledPageHeaderButtonGroupLeft = styled(PageHeaderButtonGroup)`
  flex-wrap: wrap;
`;

const StyledPageHeaderButtonGroupRight = styled(PageHeaderButtonGroup)`
  flex-grow: 1;
  margin-left: ${getRem('48px')};
`;

const PageHeaderCtaBlock = ({
  buttonsInOverflowMenuCount,
  buttonsPropsLeft,
  buttonsPropsRight,
  overflowMenuProps,
  screenSize,
  ...other
}) => {
  const hasButtonLeft = buttonsPropsLeft && buttonsPropsLeft.length > 0;
  const hasButtonRight = buttonsPropsRight && buttonsPropsRight.length > 0;

  const buttonsLeftCount = (hasButtonLeft && buttonsPropsLeft.length) || 0;
  const buttonsRightCount = (hasButtonRight && buttonsPropsRight.length) || 0;
  const allButtonsCount = buttonsLeftCount + buttonsRightCount;
  const allDefaultOptionsCount = overflowMenuProps.defaultOptions ? overflowMenuProps.defaultOptions.length : 0;

  const hasTotalOneButton = allDefaultOptionsCount + allButtonsCount === 1;
  const hasMaximumAllowedRightButtons = buttonsRightCount <= MAX_ALLOWED_BUTTON_GROUP_RIGHT;
  const buttonsOutsideOverflowMenuCount =
    buttonsInOverflowMenuCount <= buttonsLeftCount ? buttonsLeftCount - buttonsInOverflowMenuCount : buttonsLeftCount;
  const hasMinimumOptions = buttonsInOverflowMenuCount + allDefaultOptionsCount >= OVERFLOW_MENU_MIN_ALLOWED_BUTTON;

  const getShowButtonGroupRight = () => {
    if (screenSize === SCREEN_SIZES.SMALL) {
      return hasTotalOneButton;
    }
    return hasMaximumAllowedRightButtons;
  };

  const getShowButtonGroupLeft = () => {
    if (screenSize === SCREEN_SIZES.SMALL) {
      return hasTotalOneButton;
    }
    if (screenSize === SCREEN_SIZES.MEDIUM) {
      return buttonsLeftCount + allDefaultOptionsCount <= 1;
    }
    return true;
  };

  const getOverflowMenuButtonsProps = () => {
    if (screenSize === SCREEN_SIZES.SMALL) {
      return [...buttonsPropsRight, ...buttonsPropsLeft];
    }
    if (screenSize === SCREEN_SIZES.MEDIUM) {
      return buttonsPropsLeft;
    }
    return buttonsPropsLeft.slice(buttonsOutsideOverflowMenuCount);
  };

  const getShowButtonOverflowMenu = () => {
    if (screenSize === SCREEN_SIZES.SMALL) {
      return !hasTotalOneButton;
    }
    if (screenSize === SCREEN_SIZES.MEDIUM) {
      return buttonsLeftCount + allDefaultOptionsCount >= OVERFLOW_MENU_MIN_ALLOWED_BUTTON;
    }
    return hasMinimumOptions;
  };

  const showButtonGroupLeft = getShowButtonGroupLeft();
  const shownButtonsPropsLeft = hasMinimumOptions
    ? buttonsPropsLeft.slice(0, buttonsOutsideOverflowMenuCount)
    : buttonsPropsLeft;
  const showButtonGroupRight = getShowButtonGroupRight();
  const showOverflowMenu = getShowButtonOverflowMenu();

  return (
    <StyledWrapper screenSize={screenSize} {...other}>
      <StyledPageHeaderButtonGroupLeft
        alignment={screenSize === SCREEN_SIZES.SMALL ? BUTTON_GROUP_ALIGNMENTS.RIGHT : BUTTON_GROUP_ALIGNMENTS.LEFT}
        buttonsProps={showButtonGroupLeft ? shownButtonsPropsLeft : []}
      >
        {showOverflowMenu && (
          <PageHeaderButtonOverflowMenu
            options={getOverflowMenuButtonsProps()}
            screenSize={screenSize}
            {...overflowMenuProps}
          />
        )}
      </StyledPageHeaderButtonGroupLeft>
      {showButtonGroupRight && (
        <StyledPageHeaderButtonGroupRight alignment={BUTTON_GROUP_ALIGNMENTS.RIGHT} buttonsProps={buttonsPropsRight} />
      )}
    </StyledWrapper>
  );
};

PageHeaderCtaBlock.propTypes = {
  /** Number of buttons inside overflow menu */
  buttonsInOverflowMenuCount: PropTypes.number,
  /** Array of button props, which are applied to button component aligned to left */
  buttonsPropsLeft: PropTypes.arrayOf(
    PropTypes.shape({
      /** Replaces default tag: button or anchor with new value */
      customTagElement: PropTypes.string,
      /** If true, will visually hide text */
      hideText: PropTypes.bool,
      /** When URL is provided, element changes from button to hyperlink <a> */
      href: PropTypes.string,
      /** If not loading, will render specified icon before text */
      icon: PropTypes.node,
      /** Adds new class for icon element */
      iconClassName: PropTypes.string,
      /** Will change icon color */
      iconColor: PropTypes.oneOf(Object.values(BUTTON_ICON_COLORS)),
      /** Will set size of the icon */
      iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
      /** Disallows user to interact with the button and adjusts appearance */
      isDisabled: PropTypes.bool,
      /** If true, will display loader and adjust width to better accommodate spinner */
      isLoading: PropTypes.bool,
      /** Loader text which will be read for screen reader users */
      loaderText: PropTypes.node,
      /** Callback that is called on click */
      onClick: PropTypes.func,
      /** Callback that is called on key down */
      onKeyDown: PropTypes.func,
      /** If true, will add vertical margins to the component */
      preserveClickableArea: PropTypes.bool,
      /** Changes button height */
      size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
      /** Will display text inside button */
      text: PropTypes.node.isRequired,
      /** Default html button 'type' attribute values when button component is used */
      type: PropTypes.string,
      /** Changes button style depending on variant */
      variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
    })
  ),
  /** Array of button props, which are applied to button component aligned to right */
  buttonsPropsRight: PropTypes.arrayOf(
    PropTypes.shape({
      /** Replaces default tag: button or anchor with new value */
      customTagElement: PropTypes.string,
      /** If true, will visually hide text */
      hideText: PropTypes.bool,
      /** When URL is provided, element changes from button to hyperlink <a> */
      href: PropTypes.string,
      /** If not loading, will render specified icon before text */
      icon: PropTypes.node,
      /** Adds new class for icon element */
      iconClassName: PropTypes.string,
      /** Will change icon color */
      iconColor: PropTypes.oneOf(Object.values(BUTTON_ICON_COLORS)),
      /** Will set size of the icon */
      iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
      /** Disallows user to interact with the button and adjusts appearance */
      isDisabled: PropTypes.bool,
      /** If true, will display loader and adjust width to better accommodate spinner */
      isLoading: PropTypes.bool,
      /** Loader text which will be read for screen reader users */
      loaderText: PropTypes.node,
      /** Callback that is called on click */
      onClick: PropTypes.func,
      /** Callback that is called on key down */
      onKeyDown: PropTypes.func,
      /** If true, will add vertical margins to the component */
      preserveClickableArea: PropTypes.bool,
      /** Changes button height */
      size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
      /** Will display text inside button */
      text: PropTypes.node.isRequired,
      /** Default html button 'type' attribute values when button component is used */
      type: PropTypes.string,
      /** Changes button style depending on variant */
      variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
    })
  ),
  /** Object of properties, which are applied to PageHeaderButtonOverflowMenu component */
  overflowMenuProps: PropTypes.shape({
    /** Text for screen reader when context menu is being closed  */
    closeMenuText: PropTypes.node,
    /** List options provided for context menu */
    defaultOptions: PropTypes.arrayOf(
      PropTypes.shape({
        /** Specifies a location URL to redirect to */
        href: PropTypes.string,
        /** Will render specified icon */
        icon: PropTypes.node,
        /** Option id */
        id: PropTypes.string.isRequired,
        /** Applies disabled option styling if true */
        isDisabled: PropTypes.bool,
        /** Applies selected default option styling if true */
        isSelected: PropTypes.bool,
        /** Specifies the option label */
        label: PropTypes.node.isRequired,
        /** Function call executed on option click */
        onClick: PropTypes.func,
      })
    ),
    /** Text for screen reader when context menu is being opened  */
    showMenuText: PropTypes.node,
  }),
  /** Screen size for which the component has to render */
  screenSize: PropTypes.oneOf(Object.values(SCREEN_SIZES)),
};

PageHeaderCtaBlock.defaultProps = {
  buttonsInOverflowMenuCount: 0,
  buttonsPropsLeft: [],
  buttonsPropsRight: [],
  overflowMenuProps: {},
  screenSize: SCREEN_SIZES.LARGE,
};

export { PageHeaderCtaBlock };
