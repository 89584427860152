import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getHexToRgb, getRem, truncateText, typographyGroupLabel, visuallyHidden } from './../../../core';

const StyledListGroup = styled.div`
  box-sizing: border-box;

  &:not(:first-child) {
    border-top: ${({ theme }) => `1px solid rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.3)`};
    padding-top: ${({ hideGroupLabel }) => !hideGroupLabel && getRem('12px')};
  }
`;

const StyledListGroupLabel = styled.span`
  ${({ theme }) => typographyGroupLabel(theme)};
  ${truncateText};
  box-sizing: border-box;
  display: block;
  margin-bottom: ${({ theme }) => theme.size.spacing.small.value};
  padding-left: ${getRem('12px')};
  width: 100%;
  ${({ hideGroupLabel }) => hideGroupLabel && visuallyHidden};
`;

const ListGroup = ({ children, groupLabel, groupLabelClassName, hideGroupLabel, id, ...other }) => (
  <StyledListGroup hideGroupLabel={hideGroupLabel} {...other}>
    {!!groupLabel && (
      <StyledListGroupLabel hideGroupLabel={hideGroupLabel} id={id} className={groupLabelClassName}>
        {groupLabel}
      </StyledListGroupLabel>
    )}
    <div aria-labelledby={!!groupLabel && id} role="group">
      {children}
    </div>
  </StyledListGroup>
);

ListGroup.propTypes = {
  /** Any content inserted between ListGroup tags */
  children: PropTypes.node,
  /** List section/group label */
  groupLabel: PropTypes.node.isRequired,
  /** Adds additional className to group label wrapper */
  groupLabelClassName: PropTypes.string,
  /** If true, visually hides group label */
  hideGroupLabel: PropTypes.bool,
  /** Unique identificator for group. Required for accessibility */
  id: PropTypes.string.isRequired,
};

ListGroup.defaultProps = {
  children: null,
  groupLabelClassName: '',
  hideGroupLabel: false,
};

export { ListGroup };
