import {
  ALPHABET_LETTERS_AND_HIDDEN_REGEX,
  ALPHABET_LETTERS_REGEX,
  ALPHANUMERICS_AND_HIDDEN_REGEX,
  ALPHANUMERICS_REGEX,
  NUMBERS_AND_HIDDEN_REGEX,
  NUMBERS_REGEX,
} from '../regexes';

export const HIDEABLE_FORMAT_CHARS = {
  '&': ALPHANUMERICS_AND_HIDDEN_REGEX,
  '*': ALPHANUMERICS_REGEX,
  0: NUMBERS_AND_HIDDEN_REGEX,
  9: NUMBERS_REGEX,
  a: ALPHABET_LETTERS_REGEX,
  b: ALPHABET_LETTERS_AND_HIDDEN_REGEX,
};

const DEFAULT_INPUT_LENGTH = 75;

export const DEFAULT_MASK = '*'.repeat(DEFAULT_INPUT_LENGTH);
