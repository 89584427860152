import { css } from 'styled-components';

import { getButtonContentElementsColor } from '../../shared-styles';
import { focusOutlinesStyles, getHexToRgb } from './../../../core';

const primaryButtonLightBackgroundStates = {
  active: css`
    background-color: ${({ theme }) => theme.color.primary[900].value};
  `,
  default: css`
    background-color: ${({ theme }) => theme.color.primary[500].value};
    ${({ theme }) => getButtonContentElementsColor(theme.color.additional.light.value)}
  `,
  disabled: css`
    background-color: ${({ theme }) => theme.color.state.primary.disabled.value};
    ${({ theme }) => getButtonContentElementsColor(theme.color.text.light.disabled.value)};
  `,
  focus: css`
    background-color: ${({ theme }) => theme.color.primary[700].value};
    ${focusOutlinesStyles.primary};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.primary[700].value};
  `,
};

const primaryButtonPrimaryBackgroundStates = {
  active: css`
    background-color: ${({ theme }) => theme.color.primary[200].value};
    ${({ theme }) => getButtonContentElementsColor(theme.color.primary[700].value)}
  `,
  default: css`
    background-color: ${({ theme }) => theme.color.additional.light.value};
    ${({ theme }) => getButtonContentElementsColor(theme.color.primary[500].value)}
  `,
  disabled: css`
    ${({ theme }) => {
      const color = getHexToRgb(theme.color.additional.light.value);
      return css`
        background-color: ${`rgba(${color}, 0.15)`};
        ${getButtonContentElementsColor(`rgba(${color}, 0.4)`)};
      `;
    }}
  `,
  focus: css`
    background-color: ${({ theme }) => theme.color.primary[100].value};
    ${focusOutlinesStyles.light};
    ${({ theme }) => getButtonContentElementsColor(theme.color.primary[600].value)}
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.primary[100].value};
    ${({ theme }) => getButtonContentElementsColor(theme.color.primary[600].value)}
  `,
};

export const primaryButtonStateStyles = {
  default: primaryButtonLightBackgroundStates,
  defaultOnPrimaryBackground: primaryButtonPrimaryBackgroundStates,
};

export const getStateStyles = (isOnPrimaryBackground) => {
  return isOnPrimaryBackground ? primaryButtonStateStyles.defaultOnPrimaryBackground : primaryButtonStateStyles.default;
};
