import { NormalizedButton } from 'lib/button';
import styled from 'styled-components';

import { getRem, removeOutline, transitionAnimation } from './../../core';

export const PanelHeaderWrapper = styled(NormalizedButton)`
  ${transitionAnimation('background-color')}
  ${removeOutline}
    align-items: center;
  background: none;
  border: none;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  min-height: ${getRem(40)};
  outline: none;
  padding: ${({ theme }) =>
    `${theme.size.spacing.medium.value} ${getRem(12)} ${theme.size.spacing.medium.value} ${
      theme.size.spacing.large.value
    }`};
  width: 100%;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.color.state.default.hover.value};
    cursor: pointer;
  }
`;
