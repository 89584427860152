const flattenListItems = (items) => {
  const result = [];

  items.forEach((item) => {
    result.push(item);

    if (item.items) {
      flattenListItems(item.items).forEach((child) => {
        result.push(child);
      });
    }
  });

  return result;
};

const findItemsById = (items, activeItemId) => {
  return items.filter((item) => {
    return item.id === activeItemId || (item.items && findItemsById(item.items, activeItemId).length);
  });
};

export const getListItemParentsIds = (items, activeItemId) => {
  const flattenedList = flattenListItems(items);
  const itemsWithActiveId = findItemsById(flattenedList, activeItemId);
  const parents = {};

  itemsWithActiveId.forEach((item) => {
    parents[`${item.id}`] = true;
  });

  return parents;
};
