import React from 'react';

import { IconViewCard, IconViewGrid, IconViewList } from 'lib/icons';

export const VIEW_SWITCHER_SIZES = {
  LARGE: 'large',
  SMALL: 'small',
  STANDARD: 'standard',
};

export const defaultOptions = [
  { icon: <IconViewGrid />, id: 'grid-view', label: 'Grid View', value: 'grid-view' },
  { icon: <IconViewList />, id: 'list-view', label: 'List View', value: 'list-view' },
  { icon: <IconViewCard />, id: 'card-view', label: 'Card View', value: 'card-view' },
];
