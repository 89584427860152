import React from 'react';
import PropTypes from 'prop-types';

import { PageHeaderNavigationBlock, PageHeaderTitleBlock } from '../blocks';

const PageHeaderTitleWithNavigation = ({
  activeNavigationItemIndex,
  dataTestId,
  headerTitle,
  navigationItems,
  navigationTabsLabel,
  onActiveNavigationItemChange,
  ...other
}) => (
  <div data-testid={dataTestId}>
    <PageHeaderTitleBlock title={headerTitle} />
    <PageHeaderNavigationBlock
      activeItemIndex={activeNavigationItemIndex}
      dataTestId={dataTestId ? `${dataTestId}-tab` : undefined}
      items={navigationItems}
      onActiveItemChange={onActiveNavigationItemChange}
      tabsLabel={navigationTabsLabel}
      {...other}
    />
  </div>
);

PageHeaderTitleWithNavigation.propTypes = {
  /** Shows item at index as active */
  activeNavigationItemIndex: PropTypes.number.isRequired,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Specifies the page title */
  headerTitle: PropTypes.node.isRequired,
  /** Tabs data which gets rendered */
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      /** Tab identifier */
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      /** Panel identifier */
      panelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      /** Function that renders panel component */
      renderPanel: PropTypes.func.isRequired,
      /** Function that renders tab component */
      renderTab: PropTypes.func.isRequired,
    })
  ).isRequired,
  /** Provides a label that describes the purpose of the set of tabs */
  navigationTabsLabel: PropTypes.node.isRequired,
  /** Callback that is called on active item change */
  onActiveNavigationItemChange: PropTypes.func.isRequired,
};

PageHeaderTitleWithNavigation.defaultProps = {
  dataTestId: undefined,
};

export { PageHeaderTitleWithNavigation };
