import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BREAKPOINTS, getRem, typographyBody1 } from './../../../core';

const StyledDropdownOption = styled.li`
  ${({ theme }) => typographyBody1(theme)};
  background-color: ${({ isFocused, isSelected, theme }) =>
    (isSelected && theme.color.state.default.selected.value) ||
    (isFocused && theme.color.state.default.focus.value) ||
    theme.color.additional.light.value};
  font-size: ${getRem('16px')};
  line-height: ${getRem('56px')};
  list-style-type: none;
  outline: none;
  padding-left: ${getRem('12px')};

  &:hover {
    background-color: ${({ theme }) => theme.color.state.default.hover.value};
    cursor: pointer;
  }

  @media ${BREAKPOINTS.L} {
    font-size: ${getRem('14px')};
    line-height: ${getRem('40px')};
  }
`;

const DropdownOption = React.forwardRef(({ id, isFocused, isSelected, label, onClick, ...other }, ref) => {
  return (
    <StyledDropdownOption
      aria-selected={isSelected}
      onClick={onClick}
      isFocused={isFocused}
      isSelected={isSelected}
      ref={ref}
      role="option"
      {...other}
    >
      {label}
    </StyledDropdownOption>
  );
});

DropdownOption.propTypes = {
  /** Unique option identifier */
  id: PropTypes.string.isRequired,
  /** Applies focus styling */
  isFocused: PropTypes.bool,
  /** Applies selected styling */
  isSelected: PropTypes.bool,
  /** Option Label */
  label: PropTypes.node.isRequired,
  /** Callback called on option click */
  onClick: PropTypes.func.isRequired,
};

DropdownOption.defaultProps = {
  isFocused: false,
  isSelected: false,
};

export { DropdownOption };
