import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { BREAKPOINTS } from '../../core';
import { AVATAR_ICON_SIZES, AVATAR_SIZES } from '../constants';

const StyledAvatarIcon = styled.span`
  align-items: center;
  display: flex;
  fill: ${({ theme }) => theme.color.additional.light.value};
  flex-shrink: 0;
  height: ${({ size }) => AVATAR_ICON_SIZES[size]};
  justify-content: center;
  width: ${({ size }) => AVATAR_ICON_SIZES[size]};
  ${({ size }) =>
    size === AVATAR_SIZES.RESPONSIVE &&
    css`
      height: ${AVATAR_ICON_SIZES[AVATAR_SIZES.STANDARD]};
      width: ${AVATAR_ICON_SIZES[AVATAR_SIZES.STANDARD]};

      @media ${BREAKPOINTS.L} {
        height: ${AVATAR_ICON_SIZES[AVATAR_SIZES.RESPONSIVE]};
        width: ${AVATAR_ICON_SIZES[AVATAR_SIZES.RESPONSIVE]};
      }
    `};
`;

const AvatarIcon = (props) => <StyledAvatarIcon {...props} />;

AvatarIcon.propTypes = {
  /** Avatar wrapper size. Icon size changes depending on wrapper size */
  size: PropTypes.oneOf(Object.values(AVATAR_SIZES)),
};

AvatarIcon.defaultProps = {
  size: AVATAR_SIZES.STANDARD,
};

export { AvatarIcon };
